import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { createColumnHelper } from '@tanstack/react-table'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import MoreActions, { Option } from 'processingTimes/components/MoreActions'
import { formatDateSimple } from 'procurement/helpers'
import React, { useRef, useState } from 'react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { FaGear } from 'react-icons/fa6'
import { MdOutlineDragIndicator } from 'react-icons/md'
import { TbAntennaBars1, TbAntennaBars3, TbAntennaBars4, TbAntennaBars5 } from 'react-icons/tb'
import { Link, useSearchParams } from 'react-router-dom'
import { useGetTasks } from 'tasksV2/api/useQueries'
import { TASK_PRIORITIES, TASK_STATUSES, TaskV2 } from 'tasksV2/constants/tasks'
import Assignment from '../components/Assignment'
import Association from '../components/Association'
import Department from '../components/Department'
import TaskType from '../components/TaskType'
import TasksTable from './TasksTable'
import { FetchOptionSelector } from 'development/components/FetchOptionSelector'
import { renderIconMessage, truncate } from 'tasksV2/helpers'
import TaskStatus from '../components/TaskStatus'
import { differenceInCalendarDays } from 'date-fns'

export default function TaskListSmall({
    department,
    filters,
    tableSetup,
    setTableSetup,
    position,
    dragging,
    setDragging,
}: {
    department: any
    filters: any
    tableSetup: any
    setTableSetup: any
    position: number
    dragging: boolean
    setDragging: any
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [popover, setPopover] = useState<undefined | string>()
    const [dismountingPopover, setDismountingPopover] = useState(false)
    const priorityRef = useRef<HTMLDivElement>(null)
    const assignedToString = searchParams.get('assigned_to') || ''
    const typesString = searchParams.get(`${department?.title}_types`) || ''
    const page = searchParams.get(`${department?.title}_page`) || 1
    const prioritiesString = searchParams.get('priorities') || ''
    const statusString = searchParams.get('statuses') || ''
    const estimatesString = searchParams.get('estimates') || ''

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: department?.id })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    const assignedToValues = decodeURIComponent(assignedToString)
        .split(',')
        .filter((v) => v)
    // const departmentValues = decodeURIComponent(departmentsString)
    //     .split(',')
    //     .filter((v) => v)
    const typeValues = decodeURIComponent(typesString)
        .split(',')
        .filter((v) => v)
    const priorityValues = decodeURIComponent(prioritiesString)
        .split(',')
        .filter((v) => v)
    const statusValues = decodeURIComponent(statusString)
        .split(',')
        .filter((v) => v)
    const estimateValues = decodeURIComponent(estimatesString)
        .split(',')
        .filter((v) => v)

    const types = department?.types?.flat()
    const typeOptions = types?.map((type: any) => {
        return {
            label: type.value,
            value: type.id.toString(),
            icon: undefined,
        }
    })
    // const estimates = departments
    //     ?.map((department: any) => {
    //         return department.estimates.flat()
    //     })
    //     .flat()
    // const estimateOptions = estimates
    //     .filter((estimate: any) => {
    //         if (!departmentValues.length) {
    //             return estimate
    //         }
    //         if (departmentValues.includes(estimate.department_id.toString())) {
    //             return estimate
    //         }
    //     })
    //     .map((estimate: any) => {
    //         return {
    //             label: estimate.value,
    //             value: estimate.id.toString(),
    //             icon: undefined,
    //         }
    //     })
    const priorityOptions = TASK_PRIORITIES?.map((priority: any) => {
        return {
            label: priority,
            value: priority,
            icon: undefined,
        }
    })
    const statusOptions = TASK_STATUSES?.map((status: any) => {
        return {
            label: status,
            value: status,
            icon: undefined,
        }
    })

    const tasksQuery = useGetTasks({
        ...filters,
        typeIds: typeValues,
        departmentIds: department?.id === -1 ? undefined : [department?.id],
        page,
        pinned: department?.id == -1 ? true : false,
    })
    const { tasks } = tasksQuery?.data || {}

    const companyFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: typeOptions,
                title: 'Types',
                field: `${department?.title}_types`,
                values: typeValues,
                searchToggle: true,
                editSearchParams: true,
                pageString: `${department?.title}_page`,
            },
        ],
        setFunction: () => {},
        resetFunction: () =>
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('assigned_to')
                prev.delete('departments')
                prev.delete('types')
                prev.delete('priorities')
                prev.delete('statuses')
                prev.delete('estimates')
                prev.set(`${department?.title}_page`, '1')
                prev.delete('after')
                prev.delete('before')
                return prev
            }),
    }

    function handlePageIncrease() {
        if (tasks?.length === 50) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) + 1
            setSearchParams((prev: any) => {
                prev.set(`${department?.title}_page`, newPage.toString())
                return prev
            })
        }
    }
    function handlePageDecrease() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) - 1
            setSearchParams((prev: URLSearchParams) => {
                prev.set(`${department?.title}_page`, newPage.toString())
                return prev
            })
        }
    }

    const handleTogglePopover = (target: string) => {
        if (target !== popover) {
            return setPopover(target)
        }
        setDismountingPopover(true)
        setTimeout(() => {
            setPopover(undefined)
            setDismountingPopover(false)
        }, 200)
    }
    // useEffect(() => {
    //     const listenToWindow = (e: MouseEvent) => {
    //         if (!priorityRef.current!.contains(e.target as HTMLElement)) {
    //             setDismountingPopover(true)
    //             setTimeout(() => {
    //                 setPopover(undefined)
    //                 setDismountingPopover(false)
    //             }, 200)
    //         }
    //     }

    //     window.addEventListener('click', listenToWindow)

    //     return () => window.removeEventListener('click', listenToWindow)
    // }, [])

    const tableData = tasks?.length > 0 ? tasks : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<TaskV2>()

    const taskColumns = [
        columnHelper.accessor('priority', {
            cell: (info) => {
                const priorityMap = {
                    Low: {
                        icon: TbAntennaBars1,
                        className: '',
                    },
                    Medium: {
                        icon: TbAntennaBars3,
                        className: '',
                    },
                    High: {
                        icon: TbAntennaBars4,
                        className: '',
                    },
                    Urgent: {
                        icon: TbAntennaBars5,
                        className: 'text-red dark:text-lightred',
                    },
                }
                const { icon: Priority, className: priorityClassName } =
                    priorityMap[info.getValue() as keyof typeof priorityMap]
                return (
                    <div
                        id={info.row.original.id.toString()}
                        ref={priorityRef}
                        className='flex gap-2 items-center relative'
                    >
                        <Priority
                            onClick={(e) => {
                                // e.stopPropagation()
                                // return handleTogglePopover('priority')
                            }}
                            className={`text-[20px] ${priorityClassName}`}
                        />
                        {popover === 'priority' && (
                            <FetchOptionSelector
                                options={['Low', 'Medium', 'High', 'Urgent']}
                                selectedOption={info.getValue()}
                                // onClick={(option: string) => handleUpdateTask({ id: task.id, priority: option })}
                                onClick={() => {}}
                                isPending={false}
                                className='left-0 translate-x-0'
                                searchText='Set priority...'
                                dismountingPopover={dismountingPopover}
                            />
                        )}
                    </div>
                )
            },
            size: 20,
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            cell: (info) => {
                const due_at = info.cell.row.original.due_at
                const overdue =
                    differenceInCalendarDays(new Date(), new Date(due_at)) != 0 &&
                    info.row.original.status != 'Completed'
                return (
                    <div>
                        <TaskStatus taskId={info.row.original.id} dueAt={due_at} status={info.getValue()} />
                    </div>
                )
            },
        }),
        columnHelper.accessor('title', {
            header: 'Title',
            cell: (info) => {
                return (
                    <div key={`${info.row.original.id}` + 'title'} className='relative group'>
                        <div>{truncate(info.getValue(), 30)}</div>
                        {info.getValue().length > 30 && (
                            <div className='z-10 hidden group-hover:flex absolute p-[10px] min-w-[350px] top-[calc(100%-10px)] left-[-36px] cursor-auto'>
                                <div className='left-[0px] top-[0px] p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-index-4 shadow-small'>
                                    {renderIconMessage(info.getValue())}
                                </div>
                            </div>
                        )}
                    </div>
                )
            },
            size: 300,
        }),
        columnHelper.accessor('department_id', {
            header: 'Department',
            enableHiding: department?.title === 'Pinned' ? false : true,
            cell: (info) => {
                return (
                    <div key={info.row.original.id}>
                        <Department departmentId={info.getValue()} />
                    </div>
                )
            },
        }),

        columnHelper.accessor('type_id', {
            header: 'Type',
            cell: (info) => {
                return (
                    <div className='max-w-[100px]'>
                        <TaskType
                            taskId={info.row.original.id}
                            departmentId={info.row.original.department_id}
                            typeId={info.getValue()}
                        />
                    </div>
                )
            },
            maxSize: 60,
        }),
        columnHelper.accessor('associations', {
            header: 'Associations',
            cell: (info) => {
                return (
                    <div key={info.row.original.id} className='flex gap-1 flex-wrap'>
                        {info
                            .getValue()
                            .filter((association: any) => association.resource_name !== 'user')
                            .map((association: any) => (
                                <Association id={info.row.original.id} association={association} />
                            ))}
                    </div>
                )
            },
        }),
        {
            header: 'Assigned To',
            cell: ({ row }: { row: any }) => {
                return (
                    <div className='flex gap-1 flex-wrap'>
                        {row.original.associations
                            .filter((association: any) => association.resource_name === 'user')
                            .map((association: any) => (
                                <Assignment association={association} />
                            ))}
                    </div>
                )
            },
        },
        columnHelper.accessor('due_at', {
            header: 'Due At',
            cell: (info) => {
                return <p>{info.getValue() ? formatDateSimple(new Date(info.getValue())) : ''}</p>
            },
        }),
    ]

    const columnCount = taskColumns?.length.toString() || 10

    const sizeOptions: Option[] = [
        {
            displayText: 'Hide',
            icon: undefined,
            action: () => {
                const newValue = structuredClone(tableSetup)
                newValue[position] = {
                    ...newValue[position],
                    hidden: true,
                }
                localStorage.setItem('splitTasksSetupOptions', JSON.stringify(newValue))
                setTableSetup(newValue)
            },
        },

        {
            displayText: 'Small',
            icon: undefined,
            font: ' border-t border-darkgrey',
            action: () => {
                const newValue = structuredClone(tableSetup)
                newValue[position] = {
                    ...newValue[position],
                    size: 'sm',
                }
                localStorage.setItem('splitTasksSetupOptions', JSON.stringify(newValue))
                setTableSetup(newValue)
            },
        },
        {
            displayText: 'Wide',
            icon: undefined,
            action: () => {
                const newValue = structuredClone(tableSetup)
                newValue[position] = {
                    ...newValue[position],
                    size: 'wide',
                }
                localStorage.setItem('splitTasksSetupOptions', JSON.stringify(newValue))
                setTableSetup(newValue)
            },
        },
        {
            displayText: 'Tall',
            icon: undefined,
            action: () => {
                const newValue = structuredClone(tableSetup)
                newValue[position] = {
                    ...newValue[position],
                    size: 'tall',
                }
                localStorage.setItem('splitTasksSetupOptions', JSON.stringify(newValue))
                setTableSetup(newValue)
            },
        },
        {
            displayText: 'Large',
            icon: undefined,
            action: () => {
                const newValue = structuredClone(tableSetup)
                newValue[position] = {
                    ...newValue[position],
                    size: 'lg',
                }
                localStorage.setItem('splitTasksSetupOptions', JSON.stringify(newValue))
                setTableSetup(newValue)
            },
        },
    ]

    return (
        <>
            <div
                ref={setNodeRef}
                style={style}
                {...attributes}
                key={department?.id}
                className='rounded shadow-sm w-full h-full border border-lightgrey dark:border-darkaccent'
            >
                <div className='flex justify-between dark:bg-darkaccent rounded-t py-1 px-4 pr-2 bg-lightgrey'>
                    <div className='flex w-full gap-2 items-center justify-between'>
                        <div className='flex gap-2 items-center'>
                            <div className='h-full cursor-grab'>
                                <MdOutlineDragIndicator
                                    {...listeners}
                                    onMouseDown={() => setDragging(true)}
                                    className='h-full w-[20px]'
                                />
                            </div>
                            <div className='font-bold text-[18px] leading-tight'>{department?.title}</div>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <FetchingSpinner isFetching={tasksQuery.isFetching} />
                            <button
                                disabled={page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[16px] h-[16px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handlePageDecrease()
                                }}
                            >
                                <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                            </button>
                            <div className='font-bold dark:text-offwhite'>Page {page}</div>
                            <button
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[16px] h-[16px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handlePageIncrease()
                                }}
                                disabled={tasksQuery.isLoading || tasksQuery.isFetching || tasks?.length < 50}
                            >
                                <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                            </button>
                            {companyFilterBarObject.params.length &&
                                companyFilterBarObject.params.map((param: any) => {
                                    return (
                                        <param.component
                                            key={param.title + param.field}
                                            searchToggle={param.searchToggle}
                                            editSearchParams={param.editSearchParams}
                                            title={param.title}
                                            field={param.field}
                                            options={param.options}
                                            filterValues={param.values}
                                            setFilterValues={companyFilterBarObject.setFunction}
                                            pageString={param.pageString}
                                        />
                                    )
                                })}
                            <MoreActions options={sizeOptions} displayContent={{ icon: FaGear }} />
                        </div>
                    </div>
                    <div className='font-bold text-[18px] leading-tight'></div>
                </div>
                <div className='w-full h-[1px] bg-blue dark:bg-slate'></div>
                <div className='w-full h-[calc(100%-50px)] overflow-y-scroll'>
                    {!tasksQuery.isLoading && tasks?.length < 1 && (
                        <div className='flex justify-center items-center w-full h-2/3'>
                            <div className='p-2 px-4 rounded-md border'>No tasks</div>
                        </div>
                    )}
                    {tableDataMemo && !dragging && (
                        <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                            <TasksTable
                                columns={taskColumns}
                                tableData={tableDataMemo}
                                columnCount={columnCount}
                                showDepartment={department.title === 'Pinned'}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
