export const HTMLDateInputFormatter = (date: Date) => {
    const [month, day, year] = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC',
    })
        .format(date)
        .split('/')
    return `${year}-${month}-${day}`
}

export function renderIconMessage(iconMessage: string | React.ReactElement) {
    let unparsedMessage = iconMessage
    let parsedMessage = []
    if (typeof unparsedMessage === 'string') {
        while (unparsedMessage.match('\n')) {
            parsedMessage.push(unparsedMessage.slice(0, unparsedMessage.indexOf('\n')))
            parsedMessage.push(<br></br>)
            unparsedMessage = unparsedMessage.replace(unparsedMessage.slice(0, unparsedMessage.indexOf('\n') + 1), '')
        }
    }
    parsedMessage.push(unparsedMessage)
    return parsedMessage
}

export function truncate(str: string, n: number) {
    return str.length > n ? str.slice(0, n - 1) + '...' : str
}
