import { Button } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const HTMLInputFormatter = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
}).format

export default function TaskAlert({
    taskId,
    alertOffset,
    sendEmailAlert,
    sendSlackAlert,
    mutation,
    parentAlertDetails,
    setParentAlertDetails,
}: {
    taskId: number
    alertOffset: number | null
    sendEmailAlert: 1 | 0
    sendSlackAlert: 1 | 0
    mutation: any
    parentAlertDetails?: any
    setParentAlertDetails?: Function
}) {
    const settings = useSelector((state: any) => state.settings)
    const [alertDetails, setAlertDetails] = useState<any>({
        alert_offset: alertOffset,
        send_slack_alert: sendSlackAlert,
        send_email_alert: sendEmailAlert,
    })

    const originalInput = {
        alert_offset: alertOffset,
        send_slack_alert: sendSlackAlert,
        send_email_alert: sendEmailAlert,
    }

    const edited = !!Object.keys(alertDetails).find((key) => {
        return alertDetails[key as keyof typeof alertDetails] !== originalInput[key as keyof typeof originalInput]
    })
    function parseDateToPretendISO(date: Date) {
        const dateString = HTMLInputFormatter(date)
        const [month, day, year] = dateString.split('/')
        return new Date(
            `${`${year}-${month}-${day}`}T${`${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(
                -2
            )}`}:00Z`
        ).toISOString()
    }

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement }) => {
        if (setParentAlertDetails) {
            if (target.name === 'alert_offset' && !target.value) {
                return setParentAlertDetails({
                    ...alertDetails,
                    alert_offset: null,
                })
            }

            setParentAlertDetails((previousState: any) => ({
                ...structuredClone(previousState),
                [target.name]:
                    target.type === 'checkbox' ? ((target as HTMLInputElement).checked === true ? 1 : 0) : target.value,
            }))
        }
        if (target.name === 'alert_offset' && !target.value) {
            return setAlertDetails({
                ...alertDetails,
                alert_offset: null,
            })
        }

        setAlertDetails((previousState: any) => ({
            ...structuredClone(previousState),
            [target.name]:
                target.type === 'checkbox' ? ((target as HTMLInputElement).checked === true ? 1 : 0) : target.value,
        }))
    }
    const handleSaveAlert = () => {
        if (mutation.isPending && mutation.variables.alert_offset) {
            return
        }
        mutation.mutate({
            id: taskId,
            ...alertDetails,
        })
    }

    useEffect(() => {
        setAlertDetails({
            alert_offset: alertOffset,
            send_slack_alert: sendSlackAlert,
            send_email_alert: sendEmailAlert,
        })
    }, [alertOffset, sendSlackAlert, sendEmailAlert])

    if (setParentAlertDetails) {
        return (
            <div className='flex flex-col gap-1'>
                <div className='flex justify-between items-center'>
                    <h3
                        className={cn(
                            'font-bold place-content-center block dark:text-offwhite text-[12px] uppercase leading-[1]'
                        )}
                    >
                        Reminders
                    </h3>
                    {edited && !setParentAlertDetails && (
                        <Button
                            onClick={handleSaveAlert}
                            variant={'outline'}
                            size={'sm'}
                            className={cn('max-h-[25px]', mutation.isPending && 'opacity-50  pointer-events-none')}
                        >
                            Confirm
                        </Button>
                    )}
                </div>
                <div className='flex flex-col gap-1'>
                    <div className='flex gap-2'>
                        <div className='flex gap-1 items-center'>
                            <label htmlFor='send_slack_alert' className='text-xs font-bold leading-none cursor-pointer'>
                                Slack
                            </label>
                            <input
                                style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                className='cursor-pointer'
                                type='checkbox'
                                id='send_slack_alert'
                                name='send_slack_alert'
                                checked={alertDetails.send_slack_alert == 1}
                                onChange={handleChange}
                                value={alertDetails.send_slack_alert}
                            />
                        </div>
                        <div className='flex gap-1 items-center'>
                            <label htmlFor='send_email_alert' className='text-xs font-bold leading-none cursor-pointer'>
                                Email
                            </label>
                            <input
                                style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                className='cursor-pointer'
                                type='checkbox'
                                id='send_email_alert'
                                name='send_email_alert'
                                checked={alertDetails.send_email_alert == 1}
                                onChange={handleChange}
                                value={alertDetails.send_email_alert}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-1 w-full'>
                        <select
                            className='p-1 rounded leading-none text-sm bg-lightgrey dark:bg-darkbg2 dark:border dark:border-darkgrey'
                            id='alert_offset'
                            name='alert_offset'
                            value={alertDetails.alert_offset}
                            onChange={handleChange}
                        >
                            <option value=''>Do not remind</option>
                            <option value='0'>When Due</option>
                            <option value='15'>15 Minutes Before</option>
                            <option value='30'>30 Minutes Before</option>
                            <option value='60'>1 Hour Before</option>
                            <option value='1440'>1 Day Before</option>
                        </select>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='flex flex-col gap-1'>
            <div className='flex justify-between items-center min-h-[25px]'>
                <h3
                    className={cn(
                        'font-bold place-content-center',
                        setParentAlertDetails && 'block dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                    )}
                >
                    Reminders
                </h3>
                {edited && !setParentAlertDetails && (
                    <Button
                        onClick={handleSaveAlert}
                        variant={'outline'}
                        size={'sm'}
                        className={cn('max-h-[25px]', mutation.isPending && 'opacity-50  pointer-events-none')}
                    >
                        Confirm
                    </Button>
                )}
            </div>
            <div className='flex gap-2'>
                <div className='flex flex-col gap-1 items-center'>
                    <label htmlFor='send_slack_alert' className='text-xs font-bold leading-none cursor-pointer'>
                        Slack
                    </label>
                    <input
                        style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                        className='cursor-pointer mt-[5px]'
                        type='checkbox'
                        id='send_slack_alert'
                        name='send_slack_alert'
                        checked={alertDetails.send_slack_alert == 1}
                        onChange={handleChange}
                        value={alertDetails.send_slack_alert}
                    />
                </div>
                <div className='flex flex-col gap-1 items-center'>
                    <label htmlFor='send_email_alert' className='text-xs font-bold leading-none cursor-pointer'>
                        Email
                    </label>
                    <input
                        style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                        className='cursor-pointer mt-[5px]'
                        type='checkbox'
                        id='send_email_alert'
                        name='send_email_alert'
                        checked={alertDetails.send_email_alert == 1}
                        onChange={handleChange}
                        value={alertDetails.send_email_alert}
                    />
                </div>
                <div className='flex flex-col gap-1 w-full'>
                    <label htmlFor='alert_offset' className='text-xs font-bold leading-none cursor-pointer'>
                        When
                    </label>
                    <select
                        className='p-1 rounded leading-none text-sm dark:bg-darkbg1 dark:border dark:border-darkgrey'
                        id='alert_offset'
                        name='alert_offset'
                        value={alertDetails.alert_offset}
                        onChange={handleChange}
                    >
                        <option value=''>Do not remind</option>
                        <option value='0'>When Due</option>
                        <option value='15'>15 Minutes Before</option>
                        <option value='30'>30 Minutes Before</option>
                        <option value='60'>1 Hour Before</option>
                        <option value='1440'>1 Day Before</option>
                    </select>
                </div>
            </div>
        </div>
    )
}
