import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import pdfMake from 'pdfmake/build/pdfmake.js'
import { useEffect } from 'react'
import { formatDateToLocale } from '../helpers'
import { EmailInit } from './email.types'

type EmailProps = {
    email: EmailInit
    attachments: any
    isPending?: boolean
}

function formatEmailBody(unformattedBody: string, id: number) {
    const bodyOpening = /<body[^><]*>/
    const bodyClosing = /<\/body>/
    const bodyClosingGIM = /<\/body>/gim

    let finderEmail = unformattedBody.slice()
    while ((finderEmail.match(bodyClosingGIM)?.length || 0) > 1)
        finderEmail = finderEmail.replace(bodyClosing, '|/body|')

    let realStart = finderEmail.match(bodyOpening)
    let realEnd = finderEmail.match(bodyClosing)
    let realBody = finderEmail
        .slice(realStart?.index || 0, realEnd?.index)
        .replaceAll('|/body|', '')
        .replaceAll('&quot', '"')

    const body = realBody.replace((realBody.match(/((<br>)+)[\w\W]+/) || [])[1], '\n\n')

    let formattedBody = `<div>${body}</div>`

    const doc = new DOMParser().parseFromString(formattedBody, 'text/html')
    const newBody = doc.childNodes[0].childNodes[1].childNodes[0]
    const holder = document.getElementById(`${id}-holder`)
    if (!holder?.childNodes.length) holder?.appendChild(newBody)
    return newBody
}

export default function Email({ email, attachments, isPending }: EmailProps) {
    const { id } = email
    const body = email.body || ''
    const greeting = (body.match(/<pre (.|\n)+>(.|\n)+<\/pre>/g) || [''])[1]?.split('\n\n')[0]
    const openPdf = (pdf: any) => {
        const pdfDoc = pdfMake.createPdf(JSON.parse(JSON.stringify(pdf)))
        pdfDoc.open()
    }
    const filteredAttachments = attachments?.filter((a: any) => a.attachments.length > 0 && a.id === email.id)

    function downloadBase64File({ contentBase64, fileName }: { contentBase64: string; fileName: string }) {
        // const fileMimeType = () => {}
        const linkSource = `data:application/pdf;base64,${contentBase64}`
        // const linkSource = `data:${fileMimeType};base64,${contentBase64}`
        const downloadLink = document.createElement('a')
        document.body.appendChild(downloadLink)

        downloadLink.href = linkSource
        downloadLink.target = '_self'
        downloadLink.download = fileName
        downloadLink.click()
    }

    useEffect(() => {
        formatEmailBody(body.replace(greeting, '').trim(), id)
    }, [])

    return (
        <div className='flex flex-col gap-[8px] bg-white dark:bg-darkness/20 p-[16px] pb-[8px] border-[1px] dark:border-darkgrey w-[100%] min-h-[200px] js-emails-dropdown shadow-md rounded'>
            <div className=''>
                <h2 className='font-bold text-[20px] js-emails-dropdown'>{email.subject}</h2>
                <div className='flex items-center gap-[8px]'>
                    {formatDateToLocale(email.created_at)}
                    {email.delivery_success !== null ? (
                        <div className=' text-[12px]'>
                            {email.delivery_success ? (
                                <div className='flex items-center justify-center text-black font-semibold js-emails-dropdown'>
                                    <span className=' bg-[rgb(190,255,200)] p-[2px] px-[8px] rounded-full js-emails-dropdown'>
                                        Delivered
                                    </span>
                                </div>
                            ) : (
                                <div className='flex justify-center text-white font-semibold js-emails-dropdown'>
                                    <span className='bg-[#8a0f0f] p-[2px] px-[8px] rounded-full mt-[4px] js-emails-dropdown'>
                                        Not Delivered
                                    </span>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='flex justify-center text-[12px] text-white font-semibold js-emails-dropdown'>
                            <span className='bg-darkgrey p-[2px] px-[8px] rounded-full mt-[4px] js-emails-dropdown'>
                                Delivery Status Unknown
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <div className='flex flex-col gap-[4px] font-semilight text-darkgrey dark:text-grey js-emails-dropdown text-[14px]'>
                {email.respond_to && <h3>From: {email.respond_to}</h3>}
                {!!email.recipient.length && <h3>To: {email.recipient.join(', ')}</h3>}
            </div>
            <div className='border-t-[1px] border-darkgrey ' />
            {email.has_attachments === 1 && (
                <div
                    // className={cn(
                    //     filteredAttachments.map((a: any) => {
                    //         return a.attachments.length > 1 && 'flex-col', 'p-[2px] flex gap-1 max-w-[500px]'
                    //     })
                    // )}
                    className='p-[2px] flex gap-1 max-w-[500px] flex-col'
                >
                    <p className='text-darkgrey dark:text-grey'>Attachments: </p>
                    {isPending && <FetchingSpinner isFetching={true} className='my-auto' />}
                    {attachments?.length > 0 && (
                        <>
                            {filteredAttachments?.map((a: any, index: number) => {
                                return a.attachments.map((a: any) => {
                                    return (
                                        <button
                                            className='flex mr-4 border-blue dark:border-accent cursor-pointer hover:underline hover:text-blue dark:hover:text-accent text-ellipsis overflow-hidden max-w-[400px]'
                                            key={index}
                                            aria-label='download attachment'
                                            onClick={() =>
                                                downloadBase64File({
                                                    contentBase64: a.content,
                                                    fileName: a.filename,
                                                })
                                            }
                                        >
                                            <div>{a.filename}</div>
                                        </button>
                                    )
                                })
                            })}
                        </>
                    )}
                </div>
            )}
            <div className='border-[1px] dark:border-darkgrey bg-[rgb(250,250,250)] dark:bg-darkness p-[8px] rounded js-purchase-orders__email whitespace-pre-wrap font-bai'>
                <h1 className='text-[16px] font-semibold mb-[8px] js-purchase-orders__email'>{greeting}</h1>
                <div style={{ wordBreak: 'break-word' }} id={`${id}-holder`} className={`whitespace-pre-wrap`} />
            </div>
        </div>
    )
}
