type CloseButtonProps = {
    onClick?: Function
}

export default function CloseButton({ onClick = () => {} }: CloseButtonProps) {
    return (
        <button
            onClick={onClick as any}
            className='absolute top-[0px] right-[8px] z-index-4 text-red dark:text-lightred font-bold text-[24px] leading-[1]'
        >
            &times;
        </button>
    )
}
