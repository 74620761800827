import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { cn } from 'helpers'
import { useCreatePrivateEvent, useGetPrivateEvents } from 'privateEvents/api/useQueries'
import Textarea from 'procurement/components/Textarea'
import { useEffect, useState } from 'react'
import { FaTimes, FaTrash } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import PrivateEvent from 'tasks/components/PrivateEvent'
import PrivateEventModal from 'tasks/components/PrivateEventModal'
import { TASKS_PRIVATE_EVENT_URL } from 'tasks/components/TaskEditCreateModalV2'
import queryKeys from 'tasksV2/api/queryKeys'
import {
    useDeleteRecurringTask,
    useDeleteTask,
    useGetTask,
    useGetTaskEvents,
    useUpdateTask,
} from 'tasksV2/api/useQueries'
import { priorityMap, TaskV2 } from 'tasksV2/constants/tasks'
import Association from './components/Association'
import Department from './components/Department'
import TaskAlert from './components/TaskAlert'
import TaskEstimate from './components/TaskEstimate'
import TaskPriority from './components/TaskPriority'
import TaskScreenDueAt from './components/TaskScreenDueAt'
import TaskStatus from './components/TaskStatus'
import TaskType from './components/TaskType'
import CreateRecurringTaskModal from './modals/CreateRecurringTaskModal'
import TaskAssignmentsModal from './modals/TaskAssignmentsModal'
import TaskAssociationsModal from './modals/TaskAssociationsModal'
import PinnedEscalatedIcons from './PinnedEscalatedIcons'
import TextareaV2 from 'procurement/components/TextareaV2'

const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
}).format

export default function TaskScreenV2() {
    const { task_id } = useParams()
    const user = useSelector<any, any>((state) => state.user)
    const navigate = useNavigate()
    const [showDelete, setShowDelete] = useState(false)
    const [editedFields, setEditedFields] = useState<any>({})
    const [showAssignments, setShowAssignments] = useState(false)
    const [showAssociations, setShowAssociations] = useState(false)
    const [showRecurringTaskModal, setShowRecurringTaskModal] = useState(false)
    const edited = Object.keys(editedFields).length > 0
    const updateTaskMutation = useUpdateTask(task_id || '')
    const [showTaskEvents, setShowTaskEvents] = useState(false)
    const deleteTaskMutation = useDeleteTask(task_id || '')
    const deleteRecurringTaskMutation = useDeleteRecurringTask()
    const taskEventsQuery = useGetTaskEvents(task_id || '')
    const taskEvents = taskEventsQuery.data?.events || []
    const [initRender, setInitRender] = useState(true)
    const taskQuery = useGetTask(task_id || '')
    const task: TaskV2 = taskQuery.data?.task || {}
    const queryClient = useQueryClient()
    const [showEditHistory, setShowEditHistory] = useState(false)
    const { icon: PriorityIcon, className: priorityClassName } = task.priority
        ? priorityMap[task.priority]
        : { icon: undefined, className: '' }

    const createPrivateEvent = useCreatePrivateEvent()
    useEffect(() => {
        if (initRender === true && task_id) {
            createPrivateEvent.mutate({ id: task_id, user_id: user.id, privateEventUrl: TASKS_PRIVATE_EVENT_URL })
            setInitRender(false)
        }
    }, [])
    const privateEventsQuery = useGetPrivateEvents({
        privateEventUrl: TASKS_PRIVATE_EVENT_URL,
        element: 'task_id',
        id: task_id,
        enabled: true,
    })
    const { taskEvents: privateEvents } = privateEventsQuery?.data || {}

    const toggleAssociations = (association: { resource_name: string; readable_name: string; resource_id: number }) => {
        setEditedFields((prev: any) => {
            const newState = structuredClone(prev)
            if (!newState.associations) {
                newState.associations = structuredClone(task.associations)
            }
            if (!newState.disassociations) {
                newState.disassociations = []
            }
            if (
                newState.associations.find(
                    (resource: any) =>
                        resource.resource_id === association.resource_id &&
                        resource.resource_name === association.resource_name
                )
            ) {
                newState.associations = newState.associations.filter(
                    (resource: any) =>
                        !(
                            resource.resource_id === association.resource_id &&
                            resource.resource_name === association.resource_name
                        )
                )
                newState.disassociations.push(association)
                return newState
            } else {
                newState.disassociations = newState.disassociations.filter(
                    (resource: any) =>
                        !(
                            resource.resource_id === association.resource_id &&
                            resource.resource_name === association.resource_name
                        )
                )
                newState.associations.push(association)
                return newState
            }
        })
    }

    const handleDisassociation = (association: any) => {
        updateTaskMutation.mutate({
            id: task.id,
            disassociations: [association],
        })
    }

    const handleSave = () => {
        if (updateTaskMutation.isPending) {
            return
        }
        const parsedUpdates: any = {}
        if (editedFields.associations) {
            const associations = editedFields.associations.filter(
                (association: any) =>
                    !task.associations.find(
                        (resource: any) =>
                            association.resource_name === resource.resource_name &&
                            association.resource_id === resource.resource_id
                    )
            )
            const disassociations = task.associations.filter(
                (association: any) =>
                    !editedFields.associations.find(
                        (resource: any) =>
                            association.resource_name === resource.resource_name &&
                            association.resource_id === resource.resource_id
                    )
            )
            parsedUpdates.associations = associations
            parsedUpdates.disassociations = disassociations
        }
        queryClient.setQueryData(queryKeys.tasks.detail(task_id || '').queryKey, (oldData: any) => {
            return {
                ...oldData,
                task: { ...oldData.task, ...editedFields },
            }
        })
        updateTaskMutation.mutate(
            {
                id: task.id,
                ...editedFields,
                ...parsedUpdates,
            },
            {
                onSuccess: () => {
                    setEditedFields({})
                },
            }
        )
    }
    const handleDeleteTask = () => {
        deleteTaskMutation.mutate(undefined, {
            onSuccess: () => {
                navigate('/tasks')
            },
        })
    }
    const handleChange = ({ target }: { target: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement }) => {
        setEditedFields({
            ...editedFields,
            [target.name]: target.value,
        })
    }
    const handleChangePinned = () => {
        if (updateTaskMutation.isPending && updateTaskMutation.variables.pinned) {
            return
        }
        queryClient.setQueryData(queryKeys.tasks.detail(task_id || '').queryKey, (oldData: any) => {
            return {
                ...oldData,
                task: { ...oldData.task, pinned: task.pinned ? 0 : 1 },
            }
        })
        updateTaskMutation.mutate({
            id: task_id,
            pinned: task.pinned ? 0 : 1,
        })
    }
    const handleChangeEscalated = () => {
        if (updateTaskMutation.isPending && updateTaskMutation.variables.is_escalated) {
            return
        }
        queryClient.setQueryData(queryKeys.tasks.detail(task_id || '').queryKey, (oldData: any) => {
            return {
                ...oldData,
                task: { ...oldData.task, is_escalated: task.is_escalated ? 0 : 1 },
            }
        })
        updateTaskMutation.mutate({
            id: task_id,
            is_escalated: task.is_escalated ? 0 : 1,
        })
    }
    const deleteRecurringTask = () => {
        deleteRecurringTaskMutation.mutate(task.recurring_task_id.toString())
    }
    const isObject = (event: any) => event && typeof event === 'object'

    const getDifference: any = (a: any, b: any) => {
        const diffedKeys = Object.assign(
            // @ts-ignore
            ...Array.from(new Set([...Object.keys(a), ...Object.keys(b)]), (k) => ({
                [k]: isObject(a[k]) && isObject(b[k]) ? getDifference(a[k], b[k]) : a[k] === b[k],
            }))
        )
    }
    return (
        <div className='text-sm scroll pl-[calc(100vw-100%-280px)]'>
            <div className='max-w-[1000px] flex gap-4 mx-auto'>
                {taskEvents && showTaskEvents && (
                    <PrivateEventModal origin='task' setViewModal={setShowTaskEvents} events={privateEvents} />
                )}
                <div className='w-2/3 ml-auto'>
                    <div className='flex justify-between items-center'>
                        <Button
                            variant={'outline'}
                            className='mb-2 h-fit p-1 text-sm dark:border-darkgrey'
                            onClick={() => navigate(-1)}
                        >
                            &lt;- Back
                        </Button>
                        {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                            <div>
                                <PrivateEvent events={privateEvents} setViewModal={setShowTaskEvents} />
                            </div>
                        )}
                    </div>
                    <div className='mb-4'>
                        <div className='flex justify-between items-end border-b border-lightgrey dark:border-darkgrey pb-2 mb-4'>
                            <div className='flex gap-4 items-center'>
                                <h1 className='font-semibold text-2xl'>Task {task.id}</h1>
                                <FetchingSpinner isFetching={updateTaskMutation.isPending} />
                            </div>
                            <div className='flex gap-3 font-semibold text-base'>
                                {task.department_id && <Department departmentId={task.department_id} />}
                                {task.department_id && (
                                    <TaskType
                                        taskId={task.id}
                                        departmentId={task.department_id}
                                        typeId={task.type_id}
                                    />
                                )}
                            </div>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <TextareaV2
                                className='text-base w-full max-h-[200px] leading-1 bg-transparent border border-lightgrey  focus:outline-none dark:text-white dark:border-darkgrey dark:bg-darkness p-[4px] rounded-[4px]'
                                label={'Task'}
                                id='title'
                                name='title'
                                value={editedFields.title ?? task.title}
                                onChange={handleChange}
                            ></TextareaV2>
                            {task.allow_descriptions === 1 && (
                                <TextareaV2
                                    className='text-base w-full max-h-[100px] leading-1 bg-transparent border border-lightgrey  focus:outline-none dark:text-white dark:border-darkgrey dark:bg-darkness p-[4px] rounded-[4px]'
                                    label={'Additional Details'}
                                    id='description'
                                    name='description'
                                    value={editedFields.description ?? task.description}
                                    onChange={handleChange}
                                ></TextareaV2>
                            )}
                        </div>
                        <div className='flex justify-between mt-2'>
                            <div className='flex gap-4'>
                                <Button
                                    className={cn(
                                        ' opacity-50 pointer-events-none dark:border-darkgrey',
                                        edited && 'opacity-100 pointer-events-auto'
                                    )}
                                    variant={'outline'}
                                    size={'sm'}
                                    onClick={handleSave}
                                >
                                    {updateTaskMutation.isPending ? 'Saving Changes..' : 'Save Changes'}
                                </Button>
                                <Button
                                    className={cn(
                                        'opacity-50 pointer-events-none dark:border-darkgrey',
                                        edited && 'opacity-100 pointer-events-auto'
                                    )}
                                    variant={'outline'}
                                    size={'sm'}
                                    onClick={() => setEditedFields({})}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <Button
                                className='dark:border-darkgrey'
                                onClick={() => setShowEditHistory(!showEditHistory)}
                                variant={'outline'}
                                size={'sm'}
                            >
                                {showEditHistory ? 'Hide History' : 'Show History'}
                            </Button>
                        </div>
                    </div>
                    {showEditHistory &&
                        taskEvents.map((event: any) => {
                            return (
                                <div className='relative p-2 pl-4 ml-4 border-l-2 border-slate flex gap-4 justify-between'>
                                    <div className='absolute top-1/2 translate-y-[-50%] h-2 w-2 rounded-full bg-bg1 dark:bg-darkbg2 left-[-5px] border-2 border-slate'></div>
                                    <span>{event.message}</span>
                                    <span className='shrink-0 whitespace-nowrap'>
                                        {dateFormatter(new Date(event.created_at))}
                                    </span>
                                </div>
                            )
                        })}
                </div>

                <div className='w-1/3 rounded bg-lightgrey dark:bg-darkbg1 p-4 h-fit'>
                    <div className='flex justify-between'>
                        <div className='flex gap-4 items-center'>
                            <h2 className='font-semibold mb-2 text-base'>Task Details</h2>
                            {/* <FetchingSpinner isFetching={} /> */}
                        </div>
                        <PinnedEscalatedIcons
                            taskId={task?.id?.toString()}
                            mutation={updateTaskMutation}
                            pinned={task.user_pinned}
                            is_escalated={task.is_escalated}
                        />
                    </div>

                    <div className='flex flex-col gap-3'>
                        <div className='flex flex-col gap-1'>
                            <div>
                                <h3 className='font-bold'>Status</h3>
                                <TaskStatus
                                    taskId={task.id || -1}
                                    dueAt={task.due_at}
                                    status={task.status}
                                    mutation={updateTaskMutation}
                                />
                            </div>
                            <div>
                                <div className='flex gap-3 items-center'>
                                    <h3 className='font-bold'>Priority</h3>
                                    {PriorityIcon && <PriorityIcon className={`text-[20px] ${priorityClassName}`} />}
                                </div>
                                <TaskPriority
                                    taskId={task.id || -1}
                                    priority={task.priority}
                                    mutation={updateTaskMutation}
                                />
                            </div>
                            <div>
                                <h3 className='font-bold'>Estimate</h3>
                                <TaskEstimate
                                    taskId={task.id || -1}
                                    departmentId={task.department_id}
                                    estimateId={task.estimate_id}
                                    mutation={updateTaskMutation}
                                />
                            </div>
                        </div>
                        <div>
                            <TaskScreenDueAt taskId={task.id || -1} dueAt={task.due_at} mutation={updateTaskMutation} />
                        </div>
                        {task.due_at && (
                            <TaskAlert
                                taskId={task.id || -1}
                                alertOffset={task.alert_offset}
                                sendEmailAlert={task.send_email_alert}
                                sendSlackAlert={task.send_slack_alert}
                                mutation={updateTaskMutation}
                            />
                        )}
                        <div>
                            <h3 className='font-bold'>Recurring Task</h3>
                            <div className='flex gap-1 items-center'>
                                <p>{!!task.recurring_task_id ? 'Yes -' : 'No -'}</p>
                                {!!task.recurring_task_id ? (
                                    <Button onClick={deleteRecurringTask} variant={'outline'} className='h-fit p-1'>
                                        Cancel Recurring Task
                                    </Button>
                                ) : (
                                    <Button
                                        variant={'outline'}
                                        className='h-fit p-1 dark:border-darkgrey'
                                        onClick={() => setShowRecurringTaskModal(true)}
                                    >
                                        Make Recurring
                                    </Button>
                                )}
                            </div>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <h3 className='font-bold'>Assigned To</h3>
                            <div className='flex gap-1 flex-wrap'>
                                {task.associations?.filter((association: any) => association.resource_name === 'user')
                                    .length === 0 && <p>No Assignments</p>}
                                {task.associations
                                    ?.filter((association: any) => association.resource_name === 'user')
                                    .map((association: any) => (
                                        <div className='flex items-center gap-2 p-1 rounded bg-bg1 dark:bg-darkbg2'>
                                            <span>{association.readable_name}</span>
                                            {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                                                <button
                                                    onClick={() => {
                                                        handleDisassociation(association)
                                                    }}
                                                >
                                                    <FaTimes />
                                                </button>
                                            )}
                                        </div>
                                    ))}
                            </div>
                            {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                                <div className='flex flex-wrap'>
                                    <Button
                                        className='h-fit p-1 mt-1 dark:border-darkgrey'
                                        variant='outline'
                                        onClick={() => setShowAssignments(true)}
                                    >
                                        + Add / Edit Assignments
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col gap-1'>
                            <h3 className='font-bold'>Associated With</h3>
                            <div className='flex gap-1 flex-wrap'>
                                {task.associations?.filter((association: any) => association.resource_name !== 'user')
                                    .length === 0 && <p>No Associations</p>}
                                {task.associations
                                    ?.filter((association: any) => association.resource_name !== 'user')
                                    .map((association: any) => (
                                        <Association
                                            className={'h-7 flex gap-4'}
                                            association={association}
                                            id={task.id}
                                        >
                                            <Button
                                                size={'icon'}
                                                variant={'outline'}
                                                className='p-[2px] h-5 w-5'
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleDisassociation(association)
                                                }}
                                            >
                                                <FaTimes />
                                            </Button>
                                        </Association>
                                        // <div className='flex items-center gap-2 p-1 rounded bg-bg1 dark:bg-darkbg2'>
                                        //     <span>
                                        //         {association.readable_name} (
                                        //         {association.resource_name.replaceAll('_', ' ')})
                                        //     </span>
                                        //     <button
                                        //         onClick={() => {
                                        //             handleDisassociation(association)
                                        //         }}
                                        //     >
                                        //         <FaTimes />
                                        //     </button>
                                        // </div>
                                    ))}
                            </div>
                            <div className='flex flex-wrap'>
                                <Button
                                    className='h-fit p-1 mt-1 dark:border-darkgrey'
                                    variant='outline'
                                    onClick={() => setShowAssociations(true)}
                                >
                                    + Add / Edit Associations
                                </Button>
                            </div>
                        </div>
                        <div className='flex gap-1 items-center'>
                            {showDelete ? (
                                <>
                                    <Button
                                        onClick={handleDeleteTask}
                                        variant={'destructive'}
                                        className='h-fit p-1 block ml-auto text-xs'
                                    >
                                        Delete task?
                                    </Button>
                                    <Button
                                        onClick={() => setShowDelete(false)}
                                        variant={'outline'}
                                        className='h-fit p-1 block text-xs'
                                    >
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                <button onClick={() => setShowDelete(true)} className='h-fit p-1 block ml-auto'>
                                    <FaTrash className='text-red dark:text-lightred' />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAssignments && task.associations && (
                <TaskAssignmentsModal
                    closeModal={() => {
                        setShowAssignments(false)
                    }}
                    handleSave={handleSave}
                    associations={editedFields.associations ?? task.associations}
                    toggleAssociation={toggleAssociations}
                    handleReset={() => {
                        setEditedFields((prev: any) => {
                            const newState = structuredClone(prev)
                            newState.associations = structuredClone(task.associations)
                            return newState
                        })
                    }}
                />
            )}
            {showAssociations && task.associations && (
                <TaskAssociationsModal
                    closeModal={() => {
                        setShowAssociations(false)
                    }}
                    associations={editedFields.associations ?? task.associations}
                    toggleAssociation={toggleAssociations}
                    handleSave={handleSave}
                    handleReset={() => {
                        setEditedFields((prev: any) => {
                            const newState = structuredClone(prev)
                            newState.associations = structuredClone(task.associations)
                            return newState
                        })
                    }}
                />
            )}
            {showRecurringTaskModal && (
                <CreateRecurringTaskModal selectedTask={task} closeModal={() => setShowRecurringTaskModal(false)} />
            )}
        </div>
    )
}
