import { Button } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'
import { Link } from 'react-router-dom'

export default function Association({
    association,
    id,
    className,
    children,
}: {
    association: any
    id: any
    className?: string
    children?: any
}) {
    let name = `${association.resource_name} ${association.resource_id}`
    let link = `/${association.resource_name}/${association.resource_id}`
    switch (association.resource_name) {
        case 'order':
            link = `/orders/${association.resource_id}`
            break
        case 'draft_order':
            link = `/draftOrders/${association.resource_id}`
            break
        case 'project':
            link = `/tasks/projects/${association.resource_id}`
            break
        case 'customer':
            link = `/customers?customer_id=${association.resource_id}`
            break
        case 'customer_tax_exemption':
            link = `/tax-exemptions/${association.resource_id}`
            break
        case 'roadmap':
            link = `/tasks/roadmaps/${association.resource_id}`
            break
        case 'company':
            link = `/procurement?company_id=${association.resource_id}`
            break
    }
    return (
        <Button
            size={'sm'}
            variant={'outline'}
            className={cn('px-1 py-0 h-6 hover:bg-bg1 hover:scale-[1.03] transition-all', className)}
        >
            <Link
                onClick={(e) => e.stopPropagation()}
                key={`${id}` + `${association.resource_id}`}
                to={link}
                draggable={false}
            >
                {association.readable_name}
            </Link>
            {children}
        </Button>
    )
}
