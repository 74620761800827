import { useEffect, useState } from 'react'
import { closePopout } from '../../helpers'
import { useNavigate, useParams } from 'react-router'
import { OrderInit } from '../orders.types'
import { AiFillCaretDown } from 'react-icons/ai'
import { Button } from 'custom_components/component_Basics/Button'

type OrderActionsProps = {
    actions: any[]
    addNewTask: Function
    order: OrderInit
    origin: 'order' | 'draft_order'
}

export default function OrderActions({ actions, addNewTask, order, origin }: OrderActionsProps) {
    const navigate = useNavigate()
    const { orderId } = useParams()
    const [showActions, setShowActions] = useState<boolean>(false)

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => closePopout(e, ['js-order__actions'], setShowActions)
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    if (!order?.id) {
        return (
            <div className='flex font-semibold [&>button]:rounded gap-[8px]'>
                <Button
                    variant={'outline'}
                    size={'sm'}
                    className='border-blue'
                    onContextMenu={() => {
                        window.open(`/tasks/new?order_id=${orderId}`)
                    }}
                    onClick={() => addNewTask()}
                >
                    Create Task
                </Button>
                {origin === 'order' && (
                    <Button
                        variant={'outline'}
                        size={'sm'}
                        className='border-blue'
                        onContextMenu={() => {
                            window.open(`/orders/purchase-orders/new?orders=${orderId}`)
                        }}
                        onClick={() => {
                            ;(window.event as MouseEvent).ctrlKey
                                ? window.open(`/orders/purchase-orders/new?orders=${orderId}`)
                                : navigate(`/orders/purchase-orders/new?orders=${orderId}`)
                        }}
                    >
                        Create Purchase Order
                    </Button>
                )}
                {origin === 'order' && (
                    <Button
                        variant={'outline'}
                        size={'sm'}
                        className='border-blue'
                        onClick={() => {
                            window.open(`https://factorypure.myshopify.com/admin/orders/${orderId}/refund`, '_blank')
                        }}
                    >
                        {order?.financial_status && order?.financial_status === 'paid' ? 'Refund' : 'Restock'}
                    </Button>
                )}
                <Button
                    variant={'outline'}
                    size={'sm'}
                    className='border-blue'
                    onClick={() => {
                        window.open(`https://factorypure.myshopify.com/admin/${origin}s/${orderId}/edit`, '_blank')
                    }}
                >
                    Edit
                </Button>
                {actions.length > 0 && (
                    <div className='js-order__actions relative'>
                        <button
                            className='js-order__actions flex gap-[8px] items-center px-[16px] py-[8px] hover:bg-offwhite dark:hover:bg-darkaccent rounded'
                            onClick={() => {
                                !showActions ? setShowActions(true) : setShowActions(false)
                            }}
                        >
                            <span className='js-order__actions'>. . .</span>
                            <AiFillCaretDown className='pointer-events-none' />
                        </button>
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className='flex font-semibold [&>button]:rounded gap-[8px]'>
            <Button
                variant={'outline'}
                size={'sm'}
                className='border-blue'
                onContextMenu={() => {
                    window.open(`/tasks/new?order_id=${orderId}`)
                }}
                onClick={() => addNewTask()}
            >
                Create Task
            </Button>
            {origin === 'order' && (
                <Button
                    variant={'outline'}
                    size={'sm'}
                    className='border-blue'
                    onContextMenu={() => {
                        window.open(`/orders/purchase-orders/new?orders=${orderId}`)
                    }}
                    onClick={() => {
                        ;(window.event as MouseEvent).ctrlKey
                            ? window.open(`/orders/purchase-orders/new?orders=${orderId}`)
                            : navigate(`/orders/purchase-orders/new?orders=${orderId}`)
                    }}
                >
                    Create Purchase Order
                </Button>
            )}
            {origin === 'order' && (
                <Button
                    variant={'outline'}
                    size={'sm'}
                    className='border-blue'
                    onClick={() => {
                        window.open(`https://factorypure.myshopify.com/admin/orders/${orderId}/refund`, '_blank')
                    }}
                >
                    {order.financial_status === 'paid' ? 'Refund' : 'Restock'}
                </Button>
            )}
            <Button
                variant={'outline'}
                size={'sm'}
                className='border-blue'
                onClick={() => {
                    window.open(
                        origin === 'order'
                            ? `https://factorypure.myshopify.com/admin/orders/${orderId}/edit`
                            : `https://factorypure.myshopify.com/admin/draft_orders/${order.id}`,
                        '_blank'
                    )
                }}
            >
                Edit
            </Button>
            {actions.length > 0 && (
                <div className='js-order__actions relative'>
                    <button
                        className='js-order__actions flex gap-[8px] items-center px-[16px] py-[8px] hover:bg-offwhite dark:hover:bg-darkaccent rounded'
                        onClick={() => {
                            !showActions ? setShowActions(true) : setShowActions(false)
                        }}
                    >
                        <span className='js-order__actions'>. . .</span>
                        <AiFillCaretDown className='pointer-events-none' />
                    </button>
                    {showActions && (
                        <div className='js-order__actions absolute flex flex-col top-[calc(100%+4px)] right-0 min-w-[200px] p-[8px] bg-bg1 dark:bg-darkbg1 border-[1px] dark:border-darkgrey rounded shadow-small'>
                            {actions.map((action: any) => (
                                <p
                                    onClick={action.action}
                                    className='p-[8px] w-[100%] cursor-pointer hover:bg-bg1 dark:hover:bg-darkbg2 rounded'
                                >
                                    {action.name}
                                </p>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
