import { useQueryClient } from '@tanstack/react-query'
import { FaRegStar, FaStar } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import queryKeys from 'tasksV2/api/queryKeys'

export default function PinnedEscalatedIcons({
    taskId,
    mutation,
    pinned,
    is_escalated,
}: {
    taskId: string
    mutation: any
    pinned: any
    is_escalated: any
}) {
    const user = useSelector<any, any>((state) => state.user)
    const queryClient = useQueryClient()

    const handleChangePinned = () => {
        if (mutation.isPending && mutation.variables.pinned) {
            return
        }
        queryClient.setQueryData(queryKeys.tasks.detail(taskId || '').queryKey, (oldData: any) => {
            if (oldData) {
                return {
                    ...oldData,
                    task: { ...oldData.task, user_pinned: pinned ? 0 : 1 },
                }
            }
        })
        mutation.mutate({
            id: taskId,
            pinned: pinned ? 0 : 1,
        })
    }
    // const handleChangeEscalated = () => {
    //     if (mutation.isPending && mutation.variables.is_escalated) {
    //         return
    //     }
    //     queryClient.setQueryData(queryKeys.tasks.detail(taskId || '').queryKey, (oldData: any) => {
    //         return {
    //             ...oldData,
    //             task: { ...oldData.task, is_escalated: is_escalated ? 0 : 1 },
    //         }
    //     })
    //     mutation.mutate({
    //         id: taskId,
    //         is_escalated: is_escalated ? 0 : 1,
    //     })
    // }

    return (
        <div className='flex gap-1 text-xl'>
            {user.type.includes('Admin') && (
                <button title={pinned === 1 ? 'Task is pinned' : 'Pin Task'} onClick={handleChangePinned}>
                    {pinned === 1 ? <FaStar className='text-fire' /> : <FaRegStar />}
                </button>
            )}
            {/* <button
                title={task.is_escalated === 1 ? 'Task is escalated' : 'Escalate Task'}
                onClick={handleChangeEscalated}
            >
                {mutation?.variables?.is_escalated ?? is_escalated === 1 ? (
                    <IoAlertCircle className='text-red dark:text-lightred' />
                ) : (
                    <IoAlertCircleOutline />
                )}
            </button> */}
        </div>
    )
}
