import { useQuery } from '@tanstack/react-query'
import { useGetPathname } from 'productSetupV2/api/useQueries'
import queryKeys from './draftOrderQueryKeys'

export const useGetRecurringTasks = (filters: any) => {
    // return useQuery({
    //     ...queryKeys.recurringTasks.list(filters),
    // })
}

export const useGetFulfillmentOrders = (orderId: string | undefined) => {
    return useQuery({
        ...queryKeys.fulfillmentOrders.list(orderId),
        enabled: Boolean(orderId),
        meta: {
            clientErrMessage: 'Failed to load fullfillment orders',
            pathname: useGetPathname(),
            functionName: 'useGetFullfillmentOrders',
        },
    })
}
export const useGetDraftOrder = (orderId: string | undefined) => {
    return useQuery({
        ...queryKeys.draftOrders.detail(orderId),
        enabled: Boolean(orderId),
        meta: {
            clientErrMessage: 'Failed to load draft order',
            pathname: useGetPathname(),
            functionName: 'useGetDraftOrder',
        },
    })
}
export const useGetOrderEventsPrivate = (orderId: string | undefined) => {
    return useQuery({
        ...queryKeys.draftOrders.detail(orderId)._ctx.events(),
        enabled: Boolean(orderId),
        meta: {
            clientErrMessage: 'Failed to load order events',
            pathname: useGetPathname(),
            functionName: 'useGetOrderEventsPrivate',
        },
    })
}
export const useGetReturns = (orderNumber: number | undefined) => {
    return useQuery({
        ...queryKeys.returns.list(orderNumber),
        enabled: Boolean(orderNumber),
        meta: {
            clientErrMessage: 'Failed to load returns',
            pathname: useGetPathname(),
            functionName: 'useGetReturns',
        },
    })
}
export const useGetPurchaseOrders = (orderId: string | undefined) => {
    return useQuery({
        ...queryKeys.purchaseOrders.list(orderId),
        enabled: Boolean(orderId),
        meta: {
            clientErrMessage: 'Failed to load purchase orders',
            pathname: useGetPathname(),
            functionName: 'useGetPurchaseOrders',
        },
    })
}
export const useGetTimeLineEvents = (orderId: string | undefined) => {
    return useQuery({
        ...queryKeys.draftOrders.timeLineEvents(orderId),
        enabled: Boolean(orderId),
        meta: {
            clientErrMessage: 'Failed to load timeline events',
            pathname: useGetPathname(),
            functionName: 'useGetTimeLineEvents',
        },
    })
}
export const useGetOrderTaskEvents = (taskIds: string[]) => {
    return useQuery({
        ...queryKeys.draftOrders.orderTaskEvents(taskIds),
        enabled: Boolean(taskIds?.length > 0),
        meta: {
            clientErrMessage: 'Failed to load task events',
            pathname: useGetPathname(),
            functionName: 'useGetOrderTaskEvents',
        },
    })
}
export const useGetOrderEmails = (table: string, orderId: string | undefined, purchaseOrderIds: number[]) => {
    return useQuery({
        ...queryKeys.draftOrders.emails(table, orderId, purchaseOrderIds),
        enabled: Boolean(orderId),
        meta: {
            clientErrMessage: 'Failed to load emails',
            pathname: useGetPathname(),
            functionName: 'useGetOrderEmails',
        },
    })
}

// MUTATIONS
