import { useSelector } from 'react-redux'
import { useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'

export default function Department({ departmentId, title }: { departmentId: number; title?: string }) {
    const user = useSelector<any, any>((state) => state.user)
    const departmentsQuery = useGetAllDepartmentConfigs(user.id)
    const departments = departmentsQuery.data?.departments || []
    const foundDepartment = departments.find((department: any) => department.id == departmentId)
    return <div>{title || foundDepartment?.title || 'Unknown'}</div>
}
