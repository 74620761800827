import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import CloseButton from '../custom_components/CloseButton'
import { buildEmailFromTemplate, sortByAlphanumeric, vFetch } from '../helpers/index'
import { OrderCustomer, OrderInit } from '../orders/orders.types'
import { FP_SIGNATURE_LOGO_URL } from '../tasks/constants.js'
import EmailCustomerModal from './EmailCustomerModal'
import EmailManufacturerModal from './EmailManufacturerModal'

type EmailModalProps = {
    eventSource: {
        foreign_id: number | null | undefined
        foreign_table: string
    }
    mode: string | null
    setMode: Function
    handleSubmitEmailEvent?: Function
    customer: OrderCustomer
    order: OrderInit
}
export default function EmailModal({
    mode,
    setMode,
    handleSubmitEmailEvent,
    customer,
    order,
    eventSource,
}: EmailModalProps) {
    const user = useSelector<any, any>((state) => state.user)
    const outer = useRef(null)
    const [selectedTemplate, setSelectedTemplate] = useState<any>({})
    const [submitText, setSubmitText] = useState('SEND')
    const defaultEmail = {
        respond_to: 'sales@factorypure.com',
        send_to: '',
        cc: '',
        subject: '',
        body: '',
        greeting: 'Hi,',
        html: '',
    }
    const defaultTemplate = {
        name: 'Select a template',
        respond_to: 'sales@factorypure.com',
        cc: '',
        subject: '',
        greeting: '',
        html: '',
        include_signature: 1,
    }
    const [allTemplates, setAllTemplates] = useState([defaultTemplate])
    const [templates, setTemplates] = useState([defaultTemplate])
    const [email, setEmail] = useState<any>(defaultEmail)
    const [vendorContactMap, setVendorContactMap] = useState<any>({})
    const [legacyVendorContactMap, setLegacyVendorContactMap] = useState<any>({})
    const [vendorList, setVendorList] = useState<string[]>([])
    const [selectedVendor, setSelectedVendor] = useState(0)
    const [confirmCancel, setConfirmCancel] = useState(false)

    useEffect(() => {
        vFetch(`/v1/emailTemplates/all`, {
            cb: (res: any) => {
                if (res.success) {
                    setAllTemplates([
                        defaultTemplate,
                        ...res.emailTemplates.sort((a: any, b: any) => sortByAlphanumeric(a, b, 'name')),
                    ])
                    setTemplates(
                        res.emailTemplates.filter(
                            (t: any) => t.belongs_to === mode || t.belongs_to === 'any' || !t.belongs_to
                        )
                    )
                }
            },
        })
    }, [])
    useEffect(() => {
        if (order.line_items) {
            const vendors = new Set(order.line_items.map((i) => i.vendor))
            vFetch(`/v1/vendors/contacts?vendors=${encodeURIComponent(Array.from(vendors).join(','))}`, {
                cb: (res: any) => {
                    if (res.success) {
                        const resVendorContactMap: any = {}
                        Object.keys(res.vendorContactMap).forEach((v) => (resVendorContactMap[v] = []))
                        Object.keys(res.vendorContactMap).forEach((v) => {
                            res.vendorContactMap[v].forEach((contact: any) => {
                                if (
                                    !resVendorContactMap[v]?.find((object: any) => object.email === contact.email) &&
                                    contact.email &&
                                    contact.archived === 0
                                ) {
                                    resVendorContactMap[v]?.push(contact)
                                }
                            })
                        })
                        setLegacyVendorContactMap(resVendorContactMap)
                    }
                },
            })
        }
    }, [order.line_items])

    useEffect(() => {
        if (order.line_items) {
            const vendors = new Set(order.line_items.map((i) => i.vendor))
            vFetch(`/v1/procurement/contacts?vendorNames=${encodeURIComponent(Array.from(vendors).join(','))}`, {
                cb: (res: any) => {
                    if (res.success) {
                        for (let contact of res.contacts) {
                            if (!vendorContactMap[contact.vendor]) {
                                vendorContactMap[contact.vendor] = []
                            }
                            vendorContactMap[contact.vendor]?.push(contact)
                        }
                        const resVendorContactMap: any = {}
                        if (Object.keys(res.contacts).length) {
                            Array.from(vendors).forEach((vendor: string) => (resVendorContactMap[vendor] = []))
                            res.contacts.forEach((contact: any) => {
                                if (
                                    !resVendorContactMap[contact.vendor]?.find(
                                        (object: any) => object.email === contact.email
                                    ) &&
                                    contact.email
                                ) {
                                    resVendorContactMap[contact.vendor]?.push(contact)
                                }
                            })
                        }
                        setVendorContactMap(resVendorContactMap)
                        setVendorList(Array.from(vendors))
                    }
                },
            })
        }
    }, [order.line_items])

    useEffect(() => {
        const { subject, body, greeting, html } = buildEmailFromTemplate(
            selectedTemplate.name === 'Select a template'
                ? {
                      ...defaultTemplate,
                      greeting: mode === 'customer' ? 'Hi {{ customer.first_name }},' : 'Hi {{ supplier.name }},',
                  }
                : selectedTemplate,
            {
                order,
                customer,
                supplier: { name: vendorList[selectedVendor] || '' },
            }
        )
        setTemplates(allTemplates.filter((t: any) => t.belongs_to === mode || t.belongs_to === 'any' || !t.belongs_to))
        if (mode === 'customer') {
            setEmail((previousState: any) => ({
                send_to: customer.email,
                // send_to: 'ogoldsmith@factorypure.com', // swap with other send_to to hard code email recipient
                respond_to: 'sales@factorypure.com',
                cc: '',
                subject,
                body,
                greeting: greeting || `Hi ${customer.first_name},`,
                html: '',
            }))
        } else if (mode === 'manufacturer') {
            setEmail((previousState: any) => ({
                send_to:
                    (vendorList.length > 0 &&
                        vendorList[selectedVendor] && [
                            legacyVendorContactMap[vendorList[selectedVendor]]?.find((v: any) => v.email)?.email,
                        ]) ||
                    [],
                respond_to: 'sales@factorypure.com',
                cc: '',
                subject,
                body,
                greeting:
                    greeting ||
                    `Hi ${
                        vendorList.length > 0 && vendorList[selectedVendor] ? ` ${vendorList[selectedVendor]}` : ''
                    },`,
                html: '',
            }))
        } else {
            setSelectedTemplate(defaultTemplate)
            setSubmitText('SEND')
        }
    }, [mode, customer, selectedVendor])

    const [attachments, setAttachments] = useState<any>([])
    const [loadingWidth, setLoadingWidth] = useState<any>('w-0')
    const [loadingWidthWrapper, setLoadingWidthWrapper] = useState<any>('')

    const handleSendEmail = async () => {
        const { foreign_id, foreign_table } = eventSource
        // toggle comments to hard code email recipient - start
        // if (email.send_to != 'ogoldsmith@factorypure.com') {
        //     return sendToast({ message: 'Sorry only Olivia emails' })
        // }
        // if (email.send_to === 'ogoldsmith@factorypure.com') {
        setSubmitText('SENDING...')
        const parsedAttachments = []
        for (const attachment of attachments || []) {
            parsedAttachments.push({
                filename: attachment.name,
                content: await readFileAsDataURL(attachment),
                encoding: 'base64',
            })
        }
        vFetch(`/v1/email`, {
            method: 'POST',
            body: JSON.stringify({
                ...email,
                emailText: email.body.trim(),
                user_id: user.id,
                foreign_id,
                foreign_table,
                body: `<pre style="font-family: arial, sans-serif; word-wrap: break-word; white-space: pre-wrap; margin: 8px 0px;">${email.greeting.trim()}\n\n${email.body.trim()}</pre>${signatureHTMLString(
                    user
                )}`.replaceAll(/  /gm, ''),
                attachments: parsedAttachments,
            }),
            cb: (res: any) => {
                if (res.success) {
                    if (handleSubmitEmailEvent) {
                        handleSubmitEmailEvent(
                            `${email.greeting}\n\n${email.body}`,
                            email.cc,
                            email.respond_to,
                            email.subject,
                            email.send_to
                        )
                    }
                    if (attachments) {
                    }
                    setMode(null)
                    setAttachments([])
                }
            },
        })
        // } // toggle comments to hard code email recipient - end
    }

    return (
        <>
            {mode !== null && (
                <div
                    className={`grid fixed top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] z-50 place-items-center`}
                    ref={outer}
                    // onMouseDown={({ target }) => {
                    //     if (outer.current === target) {
                    //         setMode(null)
                    //         setSelectedTemplate(defaultTemplate)
                    //     }
                    // }}
                >
                    <div className='w-[80%] max-w-[600px] max-h-[90%] overflow-auto bg-white rounded-[4px] shadow-small dark:bg-darkaccent p-[16px] relative flex flex-col gap-[4px]'>
                        <CloseButton
                            onClick={() => {
                                setConfirmCancel(true)
                            }}
                        />

                        {confirmCancel && (
                            <div className='z-10 absolute items-center right-2 top-2 flex flex-col shrink-0 border shadow-md text-sm dark:border-darkgrey  whitespace-nowrap text-darkgrey dark:text-white bg-white dark:bg-darkbg1 rounded '>
                                <p className='flex gap-2 items-center dark:text-offwhite text-darkaccent m-2 font-normal'>
                                    Are you sure?
                                </p>
                                <button
                                    value='Yes - Delete'
                                    onClick={() => {
                                        setMode(null)
                                        setSelectedTemplate(defaultTemplate)
                                        setConfirmCancel(false)
                                        setAttachments([])
                                    }}
                                    className='p-1 px-[22px] rounded flex gap-2 items-center mb-1.5 mx-2 text-white dark:text-offwhite bg-red/75 dark:bg-lightred'
                                >
                                    Yes
                                </button>
                                <button
                                    value='No - Cancel'
                                    onClick={() => setConfirmCancel(false)}
                                    className='p-1 px-6 rounded flex gap-2 items-center mb-2 mx-2 text-white dark:text-offwhite bg-grey'
                                >
                                    No
                                </button>
                            </div>
                        )}
                        {/* <div className="w-[24px] h-[24px] absolute text-red font-bold leading-[1] top-[2px] right-[2px] text-[24px] cursor-pointer" onClick={() => setMode(null)}>&times;</div> */}
                        {mode === 'customer' && (
                            <EmailCustomerModal
                                templates={templates}
                                email={email}
                                setEmail={setEmail}
                                handleSendEmail={handleSendEmail}
                                selectedTemplate={selectedTemplate}
                                setSelectedTemplate={setSelectedTemplate}
                                submitText={submitText}
                                vendorList={vendorList}
                                selectedVendor={selectedVendor}
                                order={order}
                                customer={customer}
                                attachments={attachments}
                                setAttachments={setAttachments}
                                loadingWidth={loadingWidth}
                                setLoadingWidth={setLoadingWidth}
                                loadingWidthWrapper={loadingWidthWrapper}
                                setLoadingWidthWrapper={setLoadingWidthWrapper}
                            />
                        )}
                        {mode === 'manufacturer' && (
                            <EmailManufacturerModal
                                templates={templates}
                                email={email}
                                setEmail={setEmail}
                                handleSendEmail={handleSendEmail}
                                selectedTemplate={selectedTemplate}
                                setSelectedTemplate={setSelectedTemplate}
                                submitText={submitText}
                                vendorList={vendorList}
                                vendorContactMap={vendorContactMap}
                                legacyVendorContactMap={legacyVendorContactMap}
                                selectedVendor={selectedVendor}
                                setSelectedVendor={setSelectedVendor}
                                order={order}
                                customer={customer}
                                attachments={attachments}
                                setAttachments={setAttachments}
                                loadingWidth={loadingWidth}
                                setLoadingWidth={setLoadingWidth}
                                loadingWidthWrapper={loadingWidthWrapper}
                                setLoadingWidthWrapper={setLoadingWidthWrapper}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

const signatureHTMLString = (user: any) => {
    return `<div id="email-signature" style="display:grid;gap:8px 8px; row-gap:8px; column-gap:8px; grid-gap:8px;">
        <div style="display:grid">
            <strong>${user.firstName} with FactoryPure</strong>
            <strong>Sales & Customer Service: 888-999-1522 (M-F 8-5:30 PM CT)</strong>
        </div>
        <img 
            src="${FP_SIGNATURE_LOGO_URL}" 
            alt="FactoryPure Logo"
            width="120"
            height="40"
        />
        <div style="display:flex; align-items:end">
            <a style="font-size:10pt; color:#1155cc; text-decoration:underline;" href="mailto:sales@factorypure.com" rel="noopener" target="_blank">
                sales@factorypure.com
            </a>
            <span style="font-size:10pt;color:#1f497d;">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <a style="font-size:10pt; color:#1155cc; text-decoration:underline;" title="FactoryPure" href="http://www.factorypure.com/" rel="noopener" target="_blank">
                www.factorypure.com
            </a>
        </div>
    </div>`.replaceAll(/  /gm, '')
}

async function readFileAsDataURL(file: File) {
    let result_base64: string = await new Promise((resolve) => {
        let fileReader = new FileReader()
        fileReader.onload = (e) => resolve(fileReader.result as string)
        fileReader.readAsDataURL(file)
    })
    return result_base64.split('base64,')[1]
}
