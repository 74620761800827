import { Button } from 'custom_components/component_Basics/Button'
import Select from 'procurement/components/Select'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import NotFound from 'routes/NotFound'
import {
    useDeleteProject,
    useGetProject,
    useGetProjectEvents,
    useGetProjectTasks,
    useUpdateProject,
} from 'tasksV2/api/useQueries'
import { TASK_STATUSES } from 'tasksV2/constants/tasks'
import CreateTaskModal from 'tasksV2/tasks/modals/CreateTaskModal'
import ProjectTask from './components/ProjectTask'
import { useState } from 'react'
import { cn } from 'helpers'
import { FaRegStar, FaStar, FaTimes, FaTrash } from 'react-icons/fa'
import { RiGitRepositoryPrivateFill, RiGitRepositoryPrivateLine } from 'react-icons/ri'
import ProjectDatePicker from './components/ProjectDatePicker'
import { useAllUsersQuery } from 'users/api/useQueries'
import ProjectAssignmentsModal from './modals/ProjectAssignmentsModal'
import ProjectAssociationsModal from './modals/ProjectAssociationsModal'

const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
}).format

export default function ProjectScreen() {
    const { project_id } = useParams()
    const navigate = useNavigate()
    const projectQuery = useGetProject(project_id!)
    const projectTasksQuery = useGetProjectTasks(project_id!)
    const allUsersQuery = useAllUsersQuery()
    const users: any[] = (allUsersQuery.data?.users || []).filter((u: any) => !u.role?.includes('suspended'))
    const project = projectQuery.data?.project
    const deleteProjectMutation = useDeleteProject(project_id!)
    const projectEventsQuery = useGetProjectEvents(project_id!)
    const projectEvents = projectEventsQuery.data?.events || []
    const updateProjectMutation = useUpdateProject(project_id!)
    const tasks = projectTasksQuery.data?.tasks || []
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateTaskModal = searchParams.get('showCreateTaskModal') === 'true'
    const [editedFields, setEditedFields] = useState<any>({})
    const [showDelete, setShowDelete] = useState(false)
    const [showAssignments, setShowAssignments] = useState(false)
    const [showAssociations, setShowAssociations] = useState(false)
    const edited = Object.keys(editedFields).length > 0

    const toggleCreateTaskModal = () => {
        if (showCreateTaskModal) {
            searchParams.delete('showCreateTaskModal')
            return setSearchParams(searchParams)
        }
        searchParams.set('showCreateTaskModal', 'true')
        setSearchParams(searchParams)
    }
    const handleChangeStatus = ({ target }: any) => {
        updateProjectMutation.mutate({
            status: target.value,
        })
    }
    const handleChangePinned = () => {
        updateProjectMutation.mutate({
            pinned: project.pinned === 1 ? 0 : 1,
        })
    }
    const handleChangePrivate = () => {
        updateProjectMutation.mutate({
            private: project.private === 1 ? 0 : 1,
        })
    }
    const handleChange = ({ target }: { target: HTMLInputElement | HTMLTextAreaElement }) => {
        setEditedFields({
            ...editedFields,
            [target.name]: target.value,
        })
    }
    const handleSave = () => {
        const parsedUpdates: any = {}
        if (editedFields.associations) {
            const associations = editedFields.associations.filter(
                (association: any) =>
                    !JSON.parse(project.associations).find(
                        (resource: any) =>
                            association.resource_name === resource.resource_name &&
                            association.resource_id === resource.resource_id
                    )
            )
            const disassociations = JSON.parse(project.associations).filter(
                (association: any) =>
                    !editedFields.associations.find(
                        (resource: any) =>
                            association.resource_name === resource.resource_name &&
                            association.resource_id === resource.resource_id
                    )
            )
            parsedUpdates.associations = associations
            parsedUpdates.disassociations = disassociations
        }
        updateProjectMutation.mutate({ ...editedFields, ...parsedUpdates }, { onSuccess: () => setEditedFields({}) })
    }
    const handleDeleteProject = () => {
        deleteProjectMutation.mutate(undefined, {
            onSuccess: () => navigate('/tasks/projects'),
        })
    }
    const handleDisassociation = (association: any) => {
        updateProjectMutation.mutate({
            id: project.id,
            disassociations: [association],
        })
    }
    const handleChangeProjectLead = ({ target }: { target: HTMLSelectElement }) => {
        const associations = []
        if (
            !JSON.parse(project.associations).find(
                (association: any) => association.resource_name === 'user' && association.resource_id == target.value
            )
        ) {
            const foundUser = users.find((u: any) => u.id == target.value)
            associations.push({
                resource_name: 'user',
                resource_id: target.value,
                readable_name: `${foundUser.first_name} ${foundUser.last_name}`,
            })
        }
        updateProjectMutation.mutate({
            id: project.id,
            project_lead: target.value,
            associations,
        })
    }
    const toggleAssociations = (association: { resource_name: string; readable_name: string; resource_id: number }) => {
        setEditedFields((prev: any) => {
            const newState = structuredClone(prev)
            if (!newState.associations) {
                newState.associations = JSON.parse(project.associations || '[]')
            }
            if (!newState.disassociations) {
                newState.disassociations = []
            }
            if (
                newState.associations.find(
                    (resource: any) =>
                        resource.resource_id === association.resource_id &&
                        resource.resource_name === association.resource_name
                )
            ) {
                newState.associations = newState.associations.filter(
                    (resource: any) =>
                        !(
                            resource.resource_id === association.resource_id &&
                            resource.resource_name === association.resource_name
                        )
                )
                newState.disassociations.push(association)
                return newState
            } else {
                newState.disassociations = newState.disassociations.filter(
                    (resource: any) =>
                        !(
                            resource.resource_id === association.resource_id &&
                            resource.resource_name === association.resource_name
                        )
                )
                newState.associations.push(association)
                return newState
            }
        })
    }
    return (
        <div className='text-sm max-w-[1000px] mx-auto'>
            {projectQuery.isLoading ? (
                <p>Loading...</p>
            ) : !project ? (
                <NotFound />
            ) : (
                <div className='flex gap-4'>
                    <div className='w-3/4'>
                        <div>
                            <Button
                                variant={'outline'}
                                className='mb-2 h-fit p-1 text-sm dark:border-darkgrey'
                                onClick={() => navigate('/tasks/projects')}
                            >
                                &lt;- Back
                            </Button>
                            <div className='flex justify-between mb-4 border-b pb-2'>
                                <div className='flex gap-3 items-center'>
                                    <h2 className='text-2xl font-bold whitespace-nowrap'>Project {project_id}</h2>
                                    <Select
                                        className='p-0 dark:border dark:border-darkgrey'
                                        id='status'
                                        name='status'
                                        value={updateProjectMutation.variables?.status || project.status}
                                        onChange={handleChangeStatus}
                                    >
                                        <option value=''>Select a status</option>
                                        {TASK_STATUSES.map((status) => (
                                            <option value={status}>{status}</option>
                                        ))}
                                    </Select>
                                </div>
                                <Button variant='outline' onClick={toggleCreateTaskModal}>
                                    + New Task
                                </Button>
                            </div>
                        </div>
                        <div className='flex flex-col gap-1 mb-2'>
                            <label
                                htmlFor='title'
                                className='text-xs font-bold leading-none text-darkgrey dark:text-offwhite uppercase '
                            >
                                Title
                            </label>
                            <input
                                className='p-1 border border-lightgrey dark:border-darkgrey w-full rounded dark:bg-darkbg1'
                                id='title'
                                name='title'
                                value={editedFields.title ?? project.title}
                                onChange={handleChange}
                                type='text'
                            />
                        </div>
                        <div className='flex flex-col gap-1 mb-2'>
                            <label
                                htmlFor='description'
                                className='text-xs font-bold leading-none text-darkgrey dark:text-offwhite uppercase'
                            >
                                Description
                            </label>
                            <textarea
                                className='h-[150px] overflow-auto p-1 border border-lightgrey dark:border-darkgrey w-full rounded dark:bg-darkbg1'
                                id='description'
                                name='description'
                                onChange={handleChange}
                                value={editedFields.description ?? project.description}
                            ></textarea>
                        </div>
                        <div className='flex gap-4 mb-4'>
                            <Button
                                className={cn(
                                    'mt-2 opacity-50 pointer-events-none',
                                    edited && 'opacity-100 pointer-events-auto'
                                )}
                                variant={'outline'}
                                onClick={handleSave}
                            >
                                {updateProjectMutation.isPending ? 'Saving Changes..' : 'Save Changes'}
                            </Button>
                            <Button
                                className={cn(
                                    'mt-2 opacity-50 pointer-events-none',
                                    edited && 'opacity-100 pointer-events-auto'
                                )}
                                variant={'outline'}
                                onClick={() => setEditedFields({})}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div
                            className={
                                !projectTasksQuery.isLoading && tasks.length === 0
                                    ? 'mb-4 pb-2 border-b border-lightgrey dark:border-darkgrey'
                                    : 'mb-4'
                            }
                        >
                            <h2 className='font-bold mb-2 uppercase text-xs text-darkgrey dark:text-offwhite'>Tasks</h2>
                            {!projectTasksQuery.isLoading && tasks.length === 0 ? (
                                <p>This project has no tasks.</p>
                            ) : (
                                <div className='grid grid-cols-[1fr_1fr_1fr_100px_100px]'>
                                    <div className='grid grid-cols-subgrid col-span-full p-2 gap-2 bg-accent1 dark:bg-darkbg1 rounded-t text-white font-bold'>
                                        <p>Status</p>
                                        <p>Title</p>
                                        <p>Type</p>
                                        <p>Assigned To</p>
                                        <p>Due At</p>
                                    </div>
                                    {tasks.map((task: any) => (
                                        <ProjectTask task={task} />
                                    ))}
                                </div>
                            )}
                        </div>
                        {showCreateTaskModal && (
                            <CreateTaskModal
                                prePopulatedValues={{
                                    associations: [
                                        {
                                            resource_name: 'project',
                                            resource_id: project_id,
                                            readable_name: project.title,
                                        },
                                    ],
                                }}
                                closeModal={toggleCreateTaskModal}
                            />
                        )}
                        <label className='text-xs font-bold leading-none text-darkgrey dark:text-offwhite uppercase'>
                            Timeline
                        </label>
                        {projectEvents.map((event: any) => (
                            <div className='relative p-2 pl-4 ml-4 border-l-2 border-slate flex gap-4 justify-between'>
                                <div className='absolute top-1/2 translate-y-[-50%] h-2 w-2 rounded-full bg-bg1 dark:bg-darkbg2 left-[-5px] border-2 border-slate'></div>
                                <span>{event.message}</span>
                                <span className='shrink-0 whitespace-nowrap'>
                                    {dateFormatter(new Date(event.created_at))}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className='w-1/4 rounded bg-lightgrey dark:bg-darkbg1 p-4 h-fit'>
                        <div className='flex justify-between'>
                            <div className='flex gap-4 items-center'>
                                <h2 className='font-semibold mb-2 text-base'>Project Details</h2>
                            </div>
                            <div className='flex gap-1 text-xl'>
                                <button
                                    title={project.pinned === 1 ? 'Unpinned project' : 'Pin project'}
                                    onClick={handleChangePinned}
                                >
                                    {project.pinned === 1 ? <FaStar className='text-fire' /> : <FaRegStar />}
                                </button>
                                <button
                                    title={project.private === 1 ? 'Project is private' : 'Make project private'}
                                    onClick={handleChangePrivate}
                                >
                                    {project.private === 1 ? (
                                        <RiGitRepositoryPrivateFill className='text-red dark:text-lightred' />
                                    ) : (
                                        <RiGitRepositoryPrivateLine />
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className='flex flex-col gap-1 mb-2'>
                            <label className='text-xs font-bold uppercase leading-none'>Project Lead</label>
                            <select
                                className='p-1 rounded dark:bg-darkbg1 dark:border dark:border-darkgrey'
                                value={project.project_lead}
                                onChange={handleChangeProjectLead}
                            >
                                <option value=''>No project lead</option>
                                {users.map((u: any) => (
                                    <option value={u.id}>
                                        {u.first_name} {u.last_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='mb-2'>
                            <h3 className='font-bold'>Start Date</h3>
                            <ProjectDatePicker
                                dateType='start_date'
                                initialDate={project.start_date}
                                mutation={updateProjectMutation}
                            />
                        </div>
                        <div className='mb-2'>
                            <h3 className='font-bold'>Target Date</h3>
                            <ProjectDatePicker
                                dateType='target_date'
                                initialDate={project.target_date}
                                mutation={updateProjectMutation}
                            />
                        </div>
                        <div className='flex flex-col gap-1 mb-2'>
                            <label className='text-xs font-bold uppercase leading-none'>Project Members</label>
                            <div className='flex gap-1 flex-wrap'>
                                {JSON.parse(project.associations || '[]')?.filter(
                                    (association: any) => association.resource_name === 'user'
                                ).length === 0 && <p>No Assignments</p>}
                                {JSON.parse(project.associations || '[]')
                                    ?.filter((association: any) => association.resource_name === 'user')
                                    .map((association: any) => (
                                        <div className='flex items-center gap-2 p-1 rounded bg-bg1 dark:bg-darkbg2'>
                                            <span>{association.readable_name}</span>
                                            <button
                                                onClick={() => {
                                                    handleDisassociation(association)
                                                }}
                                            >
                                                <FaTimes />
                                            </button>
                                        </div>
                                    ))}
                            </div>
                            <div className='flex flex-wrap'>
                                <Button
                                    className='h-fit p-1 mt-1  dark:border-darkgrey'
                                    variant='outline'
                                    onClick={() => setShowAssignments(true)}
                                >
                                    + Add / Edit Assignments
                                </Button>
                            </div>
                        </div>
                        <div className='flex flex-col gap-1 mb-2'>
                            <label className='text-xs font-bold uppercase leading-none'>Associated With</label>
                            <div className='flex gap-1 flex-wrap'>
                                {JSON.parse(project.associations || '[]')?.filter(
                                    (association: any) => association.resource_name !== 'user'
                                ).length === 0 && <p>No Associations</p>}
                                {JSON.parse(project.associations || '[]')
                                    ?.filter((association: any) => association.resource_name !== 'user')
                                    .map((association: any) => (
                                        <div className='flex items-center gap-2 p-1 rounded bg-bg1 dark:bg-darkbg2'>
                                            <span>
                                                {association.readable_name} (
                                                {association.resource_name.replaceAll('_', ' ')})
                                            </span>
                                            <button
                                                onClick={() => {
                                                    handleDisassociation(association)
                                                }}
                                            >
                                                <FaTimes />
                                            </button>
                                        </div>
                                    ))}
                            </div>
                            <div className='flex flex-wrap'>
                                <Button
                                    className='h-fit p-1 mt-1  dark:border-darkgrey'
                                    variant='outline'
                                    onClick={() => setShowAssociations(true)}
                                >
                                    + Add / Edit Associations
                                </Button>
                            </div>
                        </div>
                        <div className='flex gap-1 items-center'>
                            {showDelete ? (
                                <>
                                    <Button
                                        onClick={handleDeleteProject}
                                        variant={'outline'}
                                        className='h-fit p-1 block ml-auto text-xs dark:text-darktext1 dark:border-danger'
                                    >
                                        Delete project
                                    </Button>
                                    <Button
                                        onClick={() => setShowDelete(false)}
                                        variant={'outline'}
                                        className='h-fit p-1 block text-xs'
                                    >
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                <button onClick={() => setShowDelete(true)} className='h-fit p-1 block ml-auto'>
                                    <FaTrash className='text-red dark:text-lightred' />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {showAssignments && (
                <ProjectAssignmentsModal
                    closeModal={() => {
                        setShowAssignments(false)
                        handleSave()
                    }}
                    associations={
                        editedFields.associations?.filter((a: any) => a.resource_name === 'user') ??
                        JSON.parse(project.associations || '[]').filter((a: any) => a.resource_name === 'user')
                    }
                    toggleAssociation={toggleAssociations}
                />
            )}
            {showAssociations && (
                <ProjectAssociationsModal
                    closeModal={() => {
                        setShowAssociations(false)
                        handleSave()
                    }}
                    associations={
                        editedFields.associations?.filter((a: any) => a.resource_name !== 'user') ??
                        JSON.parse(project.associations || '[]').filter((a: any) => a.resource_name !== 'user')
                    }
                    toggleAssociation={toggleAssociations}
                />
            )}
        </div>
    )
}
