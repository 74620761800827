import { createColumnHelper } from '@tanstack/react-table'
import { Button } from 'custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import FilterToolBar from 'procurement/components/FilterToolBar'
import React, { useEffect, useState } from 'react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { LuArrowLeftToLine } from 'react-icons/lu'
import { useSearchParams } from 'react-router-dom'
import { useGetDepartments } from 'tasksV2/api/useQueries'
import { TASK_STATUSES, TaskDepartment } from 'tasksV2/constants/tasks'
import TasksV2TableSimple from 'tasksV2/tasks/lists/TasksV2TableSimple'
import { TasksScreenV2SearchContainer } from 'tasksV2/tasks/TasksScreenV2SearchContainer'
import { useAllUsersQuery } from 'users/api/useQueries'
import { UserInit } from 'users/users.types'

export default function TaskDepartmentListV2({}: {}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const searchParam = searchParams.get('search') || ''
    const page = searchParams.get('page') || 1
    const assignedToString = searchParams.get('assigned_to') || ''
    const statusString = searchParams.get('statuses') || ''
    const [debouncedSearch, setDebouncedSearch] = useState('')

    const [initRender, setInitRender] = useState(true)

    const assignedToValues = decodeURIComponent(assignedToString)
        .split(',')
        .filter((v) => v)

    const statusValues = decodeURIComponent(statusString)
        .split(',')
        .filter((v) => v)

    const statusOptions = TASK_STATUSES?.map((status: any) => {
        return {
            label: status,
            value: status,
            icon: undefined,
        }
    })

    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []
    const activeUsers = users?.filter((user) => !user.roles.includes('suspended'))
    const userOptions = activeUsers.map((user) => {
        return {
            label: user.user_id,
            value: user.id.toString(),
            icon: undefined,
        }
    })

    const dateFormat = new Intl.DateTimeFormat('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
    }).format

    const departmentsQuery: any = useGetDepartments({ search: debouncedSearch })
    const departments = departmentsQuery.data?.departments || []

    const companyFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: userOptions,
                title: 'Assigned To',
                field: 'assigned_to',
                values: assignedToValues,
                searchToggle: true,
                editSearchParams: true,
            },

            {
                component: FilterBarSelectorButton,
                options: statusOptions,
                title: 'Status',
                field: 'statuses',
                values: statusValues,
                searchToggle: false,
                editSearchParams: true,
            },
        ],
        setFunction: () => {},
        resetFunction: () =>
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('assigned_to')
                prev.delete('departments')
                prev.delete('types')
                prev.delete('priorities')
                prev.delete('statuses')
                prev.delete('estimates')
                prev.set('page', '1')
                prev.delete('after')
                prev.delete('before')
                return prev
            }),
    }

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }

    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
    }, [])

    useEffect(() => {
        if (!initRender) {
            debouncedSearch ? searchParams.set('search', debouncedSearch) : searchParams.delete('search')
            setSearchParams(searchParams, { replace: true })
            setSearchParams((prev: any) => {
                prev.set('page', 1)
                return prev
            })
        }
    }, [debouncedSearch])
    useEffect(() => {
        if (!initRender) {
            setDebouncedSearch(searchParam)
        }
    }, [searchParam])

    function handlePageIncrease() {
        if (departments?.length === 50) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) + 1
            setSearchParams((prev: any) => {
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handlePageDecrease() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) - 1
            setSearchParams((prev: URLSearchParams) => {
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handleFirstPage() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            setSearchParams((prev: URLSearchParams) => {
                prev.set('page', '1')
                return prev
            })
        }
    }
    const handleClearSearch = () => {
        setSearchParams((prev: URLSearchParams) => {
            prev.delete('search')
            prev.set('page', '1')
            return prev
        })
        setDebouncedSearch('')
    }

    const tableData = departments?.length > 0 ? departments : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<TaskDepartment>()

    const columns = [
        columnHelper.accessor('id', {
            enableGlobalFilter: false,
            header: 'id',
            cell: (info) => {
                return <div className='flex '>{info.getValue()}</div>
            },
            size: 65,
        }),

        columnHelper.accessor('title', {
            header: 'Title',
            minSize: 300,
            cell: (info) => {
                return <div>{info.getValue()}</div>
            },
        }),
        columnHelper.accessor('private', {
            header: 'Private',
            cell: (info) => {
                return <div>{info.getValue() ? 'True' : 'False'}</div>
            },
        }),
        columnHelper.accessor('allow_descriptions', {
            header: 'Allow Task Descriptions',
            cell: (info) => {
                return <div>{info.getValue() ? 'True' : 'False'}</div>
            },
        }),
        columnHelper.accessor('users', {
            header: 'User Count',
            cell: (info) => {
                return <div>{info.row.original.private ? JSON.parse(info.getValue()).length : 'All'}</div>
            },
        }),
        columnHelper.accessor('created_at', {
            maxSize: 50,
            header: 'Created',
            cell: (info) => {
                return <p>{dateFormat(new Date(info.getValue()))}</p>
            },
        }),
        columnHelper.accessor('updated_at', {
            maxSize: 50,
            header: 'Updated',
            cell: (info) => {
                return <p>{dateFormat(new Date(info.getValue()))}</p>
            },
        }),
    ]

    return (
        <>
            <div className='flex justify-between mb-4'>
                <div className='flex gap-6 items-center w-5/12'>
                    <h1 className='text-2xl font-semibold'>Departments</h1>
                    <TasksScreenV2SearchContainer setDebouncedSearch={setDebouncedSearch} />
                </div>
                <Button variant={'outline'} size={'sm'} onClick={toggleCreateModal}>
                    + New Department
                </Button>
            </div>
            <FilterToolBar
                className='dark:bg-darkbg2'
                handleClearSearch={handleClearSearch}
                filterBarObject={companyFilterBarObject}
                showSearch={false}
            >
                <FetchingSpinner isFetching={departmentsQuery.isPending} />

                {companyFilterBarObject.params.length &&
                    companyFilterBarObject.params.map((param: any) => {
                        return (
                            <param.component
                                key={param.title + param.field}
                                searchToggle={param.searchToggle}
                                editSearchParams={param.editSearchParams}
                                title={param.title}
                                field={param.field}
                                options={param.options}
                                filterValues={param.values}
                                setFilterValues={companyFilterBarObject.setFunction}
                            />
                        )
                    })}
            </FilterToolBar>
            <div>
                <>
                    <div className='w-full'>
                        {tableDataMemo && (
                            <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                                <TasksV2TableSimple
                                    columns={columns}
                                    tableData={tableDataMemo}
                                    showHeaders={true}
                                    link={`/tasks/departments?${searchParams}&` + `departmentId=`}
                                />
                            </div>
                        )}
                        {!departmentsQuery.isLoading && departments?.length < 1 && (
                            <div className='flex justify-center items-center w-full h-2/3 mt-28'>
                                <div className='p-2 px-4 rounded-md border text-lg'>No results found</div>
                            </div>
                        )}
                    </div>
                    <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                        <>
                            <button
                                disabled={page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handleFirstPage()
                                }}
                            >
                                <LuArrowLeftToLine className='fill-darkgrey dark:fill-accent' />
                            </button>
                            <button
                                disabled={page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handlePageDecrease()
                                }}
                            >
                                <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                            </button>
                        </>
                        <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                        <button
                            className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                handlePageIncrease()
                            }}
                            disabled={
                                departmentsQuery.isLoading || departmentsQuery.isFetching || departments?.length < 50
                            }
                        >
                            <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </button>
                    </div>
                </>
            </div>
        </>
    )
}
