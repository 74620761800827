import { keepPreviousData, useQueryClient } from '@tanstack/react-query'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { NoResults } from 'custom_components/NoResults'
import React, { useEffect, useState } from 'react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import TaskEditCreateModalV2 from 'tasks/components/TaskEditCreateModalV2'
import * as taskQueryKeys from 'tasksV2/api/queryKeys'
import { useGetTasks } from 'tasksV2/api/useQueries'
import { cn, useDebounce } from '../../../helpers'
import FilterToolBar from '../FilterToolBar'

export default function TaskListV2({ inModal, taskFilters }: { inModal?: boolean; taskFilters?: any }) {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const urlParamTaskId = searchParams.get('task_id')
    const page = searchParams.get('page') || 1
    const searchParam = searchParams.get('search') || ''
    const [initRender, setInitRender] = useState(true)
    const [search, setSearch] = useState(urlParamTaskId || '')
    const debouncedSearch = useDebounce(search, 300)
    const queryClient = useQueryClient()

    useEffect(() => {
        if (search === '' && urlParamTaskId) {
            navigate('/procurement')
        }
    }, [search])

    const handleClearSearch = () => {
        setSearchParams((prev: URLSearchParams) => {
            prev.set('page', '1')
            prev.delete('search')
            return prev
        })
        setSearch('')
    }

    useEffect(() => {
        debouncedSearch ? searchParams.set('search', debouncedSearch) : searchParams.delete('search')
        setSearchParams(searchParams, { replace: true })
    }, [debouncedSearch])
    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
    }, [])
    useEffect(() => {
        if (!initRender) {
            setSearch(searchParam)
        }
    }, [searchParam])

    const tasksQueryV2 = useGetTasks(
        { ...taskFilters, search: urlParamTaskId || debouncedSearch, page },
        keepPreviousData
    )
    const { tasks: tasksV2 } = tasksQueryV2?.data || {}

    tasksV2?.forEach((task: any) => {
        queryClient.setQueryData(taskQueryKeys.tasks.detail(task.id.toString() || '').queryKey, (oldData: any) => {
            if (!oldData) {
                return { success: true, task }
            }
        })
    })

    // const preFetchCalendarquery = useTasksQueryCalendar({ userId: user.id, day: null })
    function handlePageIncrease() {
        if (tasksV2?.length === 25) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) + 1
            setSearchParams((prev: any) => {
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handlePageDecrease() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) - 1
            setSearchParams((prev: URLSearchParams) => {
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }

    return (
        <>
            <div className={cn('sticky z-40 top-[50px] bg-bg1 dark:bg-darkbg1', inModal && 'top-[33px]')}>
                <FilterToolBar search={search} setSearch={setSearch} filterBarObject={undefined}></FilterToolBar>
            </div>
            <div
                className={cn(
                    'sticky z-[25] px-2 top-[90px] grid grid-cols-[45px_30px_100px_1.5fr_.8fr_.5fr_28px_1fr_1fr] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness',
                    inModal && 'top-[74px]'
                )}
            >
                <div></div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'>Id</div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'>Associations</div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'></div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'>Type</div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'>Status</div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'></div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'></div>
                <div className='absolute right-4 top-1/4'>
                    <FetchingSpinner isFetching={tasksQueryV2.isLoading || tasksQueryV2.isLoading} />
                </div>
            </div>
            <div className='p-0 pl-0 overflow-auto min-h-[65vh]'>
                <div className='mb-[8px] w-full flex gap-[16px]'></div>

                <div className='flex flex-col gap-[8px] overflow-visible'>
                    {!tasksQueryV2.isLoading &&
                        tasksV2?.map((task: any) => (
                            <React.Fragment key={task.id.toString() + 'procurementTask'}>
                                <TaskEditCreateModalV2 taskId={task.id.toString()} createEvent={false} />
                            </React.Fragment>
                        ))}
                </div>
                {!tasksQueryV2.isFetching && tasksV2?.length === 0 && (
                    <NoResults margin='mx-auto' content={`No Results For Selected Filters`} />
                )}
                <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                    <>
                        <button
                            disabled={page == 1}
                            className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                handlePageDecrease()
                            }}
                        >
                            <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </button>
                    </>
                    <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                    <button
                        className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                        onClick={() => {
                            handlePageIncrease()
                        }}
                        disabled={tasksV2?.length < 25}
                    >
                        <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                    </button>
                </div>
            </div>
        </>
    )
}
