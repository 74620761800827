import { useContext, useEffect, useState } from 'react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { cn, useDebounce } from '../../../helpers'
import { useCompaniesQuery, useSearchCompanies } from '../../api/useQueries'
import {
    COMPANY_SORT_OPTIONS,
    LEAD_TYPE_OPTIONS,
    LIFECYCLE_STATUS_OPTIONS,
    defaultCompanyFilterParams,
} from '../../constants'
import CompanyV2 from '../listItems/CompanyV2'
import CompanyV2Skeleton from '../listItems/CompanyV2Skeleton'
import { NoResults } from '../../../custom_components/NoResults'
import { CaretSortIcon } from '@radix-ui/react-icons'
import { GrRefresh } from 'react-icons/gr'
import FilterToolBar from '../FilterToolBar'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { ProcurementContext, ProcurementContextType, getFilterBarUsersActiveFirst } from 'procurement/helpers'
import { useSelector } from 'react-redux'
import CompanyQuickModal from '../modals/CompanyQuickModal'
import { useSearchParams } from 'react-router-dom'

export default function CompanyListV2({
    userSelectedCompanyFilterValues,
    setUserSelectedCompanyFilterValues,
}: {
    userSelectedCompanyFilterValues: any
    setUserSelectedCompanyFilterValues: Function
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const user = useSelector<any, any>((state) => state.user)
    const { users } = useContext<ProcurementContextType>(ProcurementContext)
    const [cursorList, setCursorList] = useState<any>([0])
    const [selectedLastCursor, setSelectedLastCursor] = useState<any>(undefined)
    const [sortIndex, setSortIndex] = useState(0)
    const [activeSort, setActiveSort] = useState<string | undefined>(undefined)
    const archivedString = searchParams.get('archived') || ''
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [showListQuickModal, setShowListQuickModal] = useState<string | undefined>()
    const [selectedLifecycle, setSelectedLifecycle] = useState(undefined)

    const archived = decodeURIComponent(archivedString) === 'true' ? true : false

    const debouncedSearch = useDebounce(search, 200)
    const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    let sortOption
    if (activeSort) {
        sortOption = COMPANY_SORT_OPTIONS[activeSort as keyof typeof COMPANY_SORT_OPTIONS][sortIndex].value || undefined
    }

    const companyFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: getFilterBarUsersActiveFirst(user, users),
                title: 'Users',
                field: 'users',
                values: userSelectedCompanyFilterValues.users,
                searchToggle: true,
            },
            {
                component: FilterBarSelectorButton,
                options: LEAD_TYPE_OPTIONS,
                title: 'Lead Type',
                field: 'lead_types',
                values: userSelectedCompanyFilterValues.lead_types,
                searchToggle: false,
            },
            {
                component: FilterBarSelectorButton,
                options: LIFECYCLE_STATUS_OPTIONS,
                title: 'Life Cycle Status',
                field: 'lifecycle_statuses',
                values: userSelectedCompanyFilterValues.lifecycle_statuses,
                searchToggle: false,
            },
        ],
        setFunction: setUserSelectedCompanyFilterValues,
        resetFunction: () =>
            setUserSelectedCompanyFilterValues({
                lead_types: [],
                lifecycle_statuses: [],
                users: [],
            }),
    }

    useEffect(() => {
        setSelectedLastCursor(undefined)
        setCursorList([0])
        setPage(1)
    }, [search, userSelectedCompanyFilterValues, activeSort, sortIndex])

    let params: any = {
        filters: {
            ...defaultCompanyFilterParams,
            lastCursor: selectedLastCursor || undefined,
            assigned_to: userSelectedCompanyFilterValues.users,
            lead_type: userSelectedCompanyFilterValues.lead_types,
            lifecycle_status: userSelectedCompanyFilterValues.lifecycle_statuses,
            pageParam: page,
            sortOption: activeSort ? sortOption : undefined,
            archived,
        },
    }
    const companiesQuery = useCompaniesQuery({ ...params, search: debouncedSearch })
    const { isLoading: loading, isFetching: fetching } = companiesQuery
    const { companies: companiesNoSearch, lastCursor } = companiesQuery?.data || {}

    const companiesSearchQuery = useSearchCompanies({ ...params, search: debouncedSearch })
    const { isLoading: loadingSearch, isFetching: fetchingSearch } = companiesSearchQuery
    const { companies: companiesSearch, lastCursor: lastCursorSearch } = companiesSearchQuery?.data || {}

    const companies = debouncedSearch ? companiesSearch : companiesNoSearch
    const currentCursor = debouncedSearch ? lastCursorSearch : lastCursor
    const isFetching = debouncedSearch ? fetchingSearch : fetching
    const isLoading = debouncedSearch ? loadingSearch : loading

    if (!isFetching && !isLoading) {
        if (currentCursor && !(currentCursor[2] === null)) {
            if (!cursorList.includes(currentCursor)) {
                setCursorList((prev: any[]) => {
                    return [...prev, currentCursor]
                })
            }
        }
    }

    function handlePageIncrease() {
        if (!(currentCursor[2] === null)) {
            setSelectedLastCursor(currentCursor)
            setPage((prev: any) => {
                return prev + 1
            })
        }
    }
    function handlePageDecrease() {
        setSelectedLastCursor(cursorList[page - 2] || undefined)
        setPage((prev: any) => Math.max(prev - 1, 1))
    }
    function handleSort(sort: string) {
        setSelectedLastCursor(undefined)
        setCursorList([0])
        setActiveSort(sort)
        if (sort !== activeSort) {
            setSortIndex(1)
        } else if (sortIndex < 2) {
            setSortIndex((prev: any) => prev + 1)
        } else {
            setSortIndex(0)
        }
    }

    return (
        <>
            <FilterToolBar search={search} setSearch={setSearch} filterBarObject={companyFilterBarObject}>
                {companyFilterBarObject.params.length &&
                    companyFilterBarObject.params.map((param: any) => {
                        return (
                            <param.component
                                key={param.title + param.field}
                                searchToggle={param.searchToggle}
                                title={param.title}
                                field={param.field}
                                options={param.options}
                                filterValues={param.values}
                                setFilterValues={companyFilterBarObject.setFunction}
                            />
                        )
                    })}
            </FilterToolBar>
            <div>
                <div className='sticky top-[50px] z-[10] grid grid-cols-[64px_200px_30px_74px_1fr_1fr_1fr_1fr_100px_100px] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                    <div className='font-bold p-[8px] uppercase text-[12px]'></div>
                    <button
                        onClick={() => {
                            handleSort('name')
                        }}
                        className='font-bold p-[8px] pr-0 uppercase text-[12px] flex gap-2'
                    >
                        <span>Name</span>
                        {activeSort === 'name' &&
                            COMPANY_SORT_OPTIONS.name.map((option, index) => {
                                if (sortIndex === index)
                                    return (
                                        <option.icon
                                            key={option.label}
                                            className={`${cn(
                                                index !== 0 && 'text-accent2 dark:text-accent2'
                                            )}self-center min-h-[18px]`}
                                        />
                                    )
                            })}
                        {activeSort !== 'name' && <CaretSortIcon />}
                    </button>
                    <button
                        onClick={() => {
                            handleSort('overall_score')
                        }}
                        className='font-bold p-[8px] pr-0 uppercase text-[12px] flex gap-2 col-start-4'
                    >
                        <span>Score</span>
                        {activeSort === 'overall_score' &&
                            COMPANY_SORT_OPTIONS.overall_score.map((option, index) => {
                                if (sortIndex === index) {
                                    return (
                                        <option.icon
                                            className={`${cn(
                                                index !== 0 && 'text-accent2 dark:text-accent2'
                                            )}self-center min-h-[18px]`}
                                        />
                                    )
                                }
                            })}
                        {activeSort !== 'overall_score' && <CaretSortIcon />}
                    </button>
                    <div className='font-bold p-[8px] uppercase text-[12px]'>Lead Type</div>
                    <div className='font-bold p-[8px] uppercase text-[12px]'>Lifecycle Stage</div>
                    <div className='font-bold p-[8px] uppercase text-[12px]'>Latest Note</div>
                    <div className='font-bold p-[8px] uppercase text-[12px]'>Next Task</div>
                    <div className='font-bold p-[8px] uppercase text-[12px]'>Created</div>
                    <div className='absolute right-4 top-1/4'>
                        <GrRefresh
                            className={cn(
                                isFetching && 'animate-spin opacity-100',
                                !isFetching && 'opacity-0',
                                '[&>*]:stroke-offwhite [&>*]:dark:stroke-offwhite '
                            )}
                        />
                    </div>
                </div>
                {(isLoading || fetchingSearch) &&
                    skeletons.map((skeleton) => {
                        return <CompanyV2Skeleton key={skeleton} />
                    })}
                {!isLoading &&
                    companies?.map((company: any) => {
                        if (company.id === 7894) {
                        }
                        return (
                            <CompanyV2
                                key={company.id}
                                company={company}
                                showListQuickModal={showListQuickModal}
                                setShowListQuickModal={setShowListQuickModal}
                                setSelectedLifecycle={setSelectedLifecycle}
                            />
                        )
                    })}
                {!isFetching && companies?.length === 0 && (
                    <NoResults margin='mx-auto' content='No Results For Selected Filters' />
                )}
                <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                    <>
                        <button
                            disabled={page === 1}
                            className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                handlePageDecrease()
                            }}
                        >
                            <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </button>
                    </>
                    <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                    <button
                        className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                        onClick={() => {
                            handlePageIncrease()
                        }}
                        disabled={isLoading || isFetching || companies?.length < 25}
                    >
                        <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                    </button>
                </div>
                {showListQuickModal && (
                    <CompanyQuickModal
                        selectedLifecycle={selectedLifecycle}
                        showQuickModal={showListQuickModal}
                        setShowQuickModal={setShowListQuickModal}
                    />
                )}
            </div>
        </>
    )
}
