import { keepPreviousData } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { SkeletonBasic } from 'custom_components/component_Basics/SkeletonBasic'
import React, { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import TaskEditCreateModalV2 from 'tasks/components/TaskEditCreateModalV2'
import { useGetTasks } from 'tasksV2/api/useQueries'
import { Events } from '../orders/components/Events'
import { OrderInit } from '../orders/orders.types'
import { useGetOrder } from './api/useQueries'
import { cn } from 'helpers'

export default function OrderHubSkeletonBody({
    tabs,
    events,
    showActivity,
}: {
    tabs: any
    events: any
    showActivity: any
}) {
    const { orderId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedTab, setSelectedTab] = useState<string>('tasks')
    const showCreateModal = searchParams.get('showCreateModal') === 'true'

    const orderDetailQuery = useGetOrder(orderId)
    const { order }: { order: OrderInit } = orderDetailQuery?.data || {}

    const tasksQuery = useGetTasks({ resourceTable: 'order_tasks', resourceId: orderId }, keepPreviousData)
    const { tasks } = tasksQuery?.data || {}

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }

    return (
        <div className='grid grid-cols-3 gap-[4px] items-start justify-start max-h-[760px]'>
            {/* left column */}
            <div
                className={cn(
                    'grid grid-cols-subgrid items-start gap-[8px] basis-[calc(33.3%-32px)] grow min-w-[450px] p-1 h-[83vh] overflow-x-scroll',
                    !showActivity && 'col-span-2'
                )}
            >
                <div className='flex flex-col gap-2'>
                    <div className='h-[320px] shadow-small rounded-md'>
                        <SkeletonBasic className='w-full h-full' />
                    </div>
                    <div className='h-[180px] shadow-small rounded-md'>
                        <SkeletonBasic className='w-full h-full' />
                    </div>
                    <div className='h-[180px] shadow-small rounded-md'>
                        <SkeletonBasic className='w-full h-full' />
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='h-[180px] shadow-small rounded-md'>
                        <SkeletonBasic className='w-full h-full' />
                    </div>
                    <div className='h-[100px] shadow-small rounded-md'>
                        <SkeletonBasic className='w-full h-full' />
                    </div>
                    <div className='h-[300px] shadow-small rounded-md'>
                        <SkeletonBasic className='w-full h-full' />
                    </div>
                    <div className='h-[100px] shadow-small rounded-md'>
                        <SkeletonBasic className='w-full h-full' />
                    </div>
                </div>
            </div>

            <div className='flex flex-wrap gap-[8px] basis-[calc(66.6%-32px)] grow items-start justify-start'>
                {/* middle column  */}
                <div className='flex flex-col gap-x-[8px] basis-[calc(33%-32px)] min-w-[400px] grow shrink h-[83vh] overflow-x-scroll'>
                    <div className='relative top-[1px] flex gap-[8px] ml-[8px] border-b-darkgrey'>
                        {Object.keys(tabs.middle).map((tab, index) => {
                            const count =
                                tab === 'tasks'
                                    ? order?.task_count || tabs.middle[tab]?.length || 0
                                    : tabs.middle[tab]?.length || 0
                            return (
                                <button
                                    key={index}
                                    className={`border-black dark:border-darkgrey border-[1px] border-b-0 ${
                                        selectedTab === tab
                                            ? 'bg-white dark:bg-darkness z-index-1'
                                            : '!border-transparent'
                                    } rounded-t px-[4px] capitalize`}
                                    onClick={() => setSelectedTab(tab)}
                                >
                                    {tab} ({count})
                                </button>
                            )
                        })}
                    </div>
                    <div className='grid gap-[8px] border-[1px] border-black dark:border-darkgrey rounded p-[8px] h-[83vh] overflow-x-scroll'>
                        {selectedTab === 'tasks' && (
                            <div className='flex flex-col gap-[8px]'>
                                {(!tasksQuery.isSuccess || tasks?.length < 1) &&
                                    [0, 1].map((task: any, i: number) => (
                                        <React.Fragment key={task.id}>
                                            <TaskEditCreateModalV2 createEvent={false} taskId={''} />
                                        </React.Fragment>
                                    ))}
                                {tasks?.length > 0 &&
                                    tasks.map((task: any, i: number) => (
                                        <React.Fragment key={task.id}>
                                            <TaskEditCreateModalV2 createEvent={false} taskId={task.id.toString()} />
                                        </React.Fragment>
                                    ))}
                                <div className='flex justify-start items-center gap-[8px]'>
                                    <Button
                                        variant={'outline'}
                                        size={'sm'}
                                        onClick={() => toggleCreateModal()}
                                        className='flex gap-1 items-center'
                                    >
                                        <p> New Task</p>
                                        <p className='mb-[2px]'>+</p>
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* right column */}
            </div>
            {showActivity && (
                <div className='flex flex-col gap-[8px] basis-[calc(33%-32px)] min-w-[400px] grow shrink h-[83vh] overflow-x-scroll'>
                    <Events events={events} tasks={tasks} />
                </div>
            )}
        </div>
    )
}
