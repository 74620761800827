import { keepPreviousData, useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { addBusinessDays } from 'date-fns'
import InvoicePaymentStatus from 'orders/components/InvoicePaid'
import ManufacturerOrderNumbers from 'orders/components/ManufacturerOrderNumbers'
import ReturnsBlock from 'orders/components/ReturnsBlock'
import Tags from 'orders/components/Tags'
import { useCreatePrivateEvent, useGetPrivateEvents } from 'privateEvents/api/useQueries'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import TaskEditCreateModalV2 from 'tasks/components/TaskEditCreateModalV2'
import * as taskQueryKeys from 'tasksV2/api/queryKeys'
import { useGetAllDepartmentConfigs, useGetTasks } from 'tasksV2/api/useQueries'
import { HTMLDateInputFormatter } from 'tasksV2/helpers'
import CreateTaskModal from 'tasksV2/tasks/modals/CreateTaskModal'
import { useAllUsersQuery } from 'users/api/useQueries'
import { cn, createOrderNotesArray } from '../helpers'
import { Events } from '../orders/components/Events'
import FraudAnalysis from '../orders/components/FraudAnalysis'
import FulfilledItems from '../orders/components/lineItems/FulfilledItems'
import RemovedItems from '../orders/components/lineItems/RemovedItems'
import UnfulfilledItems from '../orders/components/lineItems/UnfulfilledItems'
import OrderHeader from '../orders/components/OrderHeader'
import PaymentStatus from '../orders/components/PaymentStatus'
import { OrderInit } from '../orders/orders.types'
import PurchaseOrdersList from '../purchaseOrders/components/PurchaseOrdersList'
import { PurchaseOrderEditInit } from '../purchaseOrders/purchaseOrders.types'
import { TaskEditInit } from '../tasks/tasks.types'
import { UserInit } from '../users/users.types'
import * as orderQueryKeys from './api/queryKeys'
import {
    useGetFulfillmentOrders,
    useGetOrder,
    useGetOrderEmails,
    useGetOrderTaskEvents,
    useGetPurchaseOrders,
    useGetReturns,
    useGetTimeLineEvents,
} from './api/useQueries'
import OrderHubSkeletonBody from './OrderHubSkeletonBody'

export default function OrderHubScreenV2() {
    const ORDER_PRIVATE_EVENTS_URL = '/v1/orderEventsPrivate'
    const { orderId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedTab, setSelectedTab] = useState<string>('tasks')
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const [adminEventLogged, setAdminEventLogged] = useState(false)
    const user = useSelector<any, any>((state) => state.user)
    const queryClient = useQueryClient()
    const [showActivity, setShowActivity] = useState(true)

    const createPrivateEvent = useCreatePrivateEvent()

    const orderDetailQuery = useGetOrder(orderId)
    const { order }: { order: OrderInit } = orderDetailQuery?.data || {}

    const timeLineEventsQuery = useGetTimeLineEvents(order?.id?.toString())
    const { orderEvents } = timeLineEventsQuery?.data || {}

    const privateEventsQuery = useGetPrivateEvents({
        privateEventUrl: ORDER_PRIVATE_EVENTS_URL,
        element: 'order_id',
        id: parseInt(order?.id?.toString() || ''),
        enabled: true,
    })
    const { orderEventsPrivate } = privateEventsQuery?.data || {}

    const fulfillmentOrdersQuery = useGetFulfillmentOrders(orderId)
    const { fulfillment_orders: fulfillmentOrders } = fulfillmentOrdersQuery?.data || {}

    const purchaseOrdersQuery = useGetPurchaseOrders(orderId)
    const { purchase_orders: purchaseOrders }: { purchase_orders: PurchaseOrderEditInit[] } =
        purchaseOrdersQuery?.data || []

    const returnsQuery = useGetReturns(order?.order_number)
    const { returns } = returnsQuery?.data || {}

    const tasksQuery = useGetTasks({ resourceTable: 'order_tasks', resourceId: orderId }, keepPreviousData)
    const { tasks } = tasksQuery?.data || {}

    const emailsQuery = useGetOrderEmails(orderId, purchaseOrders?.map((po: any) => po.id))
    const { emails } = emailsQuery?.data || {}

    const orderTags = order?.tags?.split(',')

    const taskIds = tasks?.map((taskV2: any) => taskV2.id)
    const taskEventsQuery = useGetOrderTaskEvents(taskIds)
    const { events: taskEvents } = taskEventsQuery?.data || {}

    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []

    const departmentsQuery = useGetAllDepartmentConfigs(user.id)
    const { departments } = departmentsQuery?.data || {}

    // seed cache
    tasks?.forEach((task: any) => {
        queryClient.setQueryData(taskQueryKeys.tasks.detail(task.id.toString() || '').queryKey, (oldData: any) => {
            if (!oldData) {
                return { success: true, task }
            }
        })
    })

    const allLoaded =
        !orderDetailQuery.isLoading &&
        !timeLineEventsQuery.isLoading &&
        !privateEventsQuery.isLoading &&
        !fulfillmentOrdersQuery.isLoading &&
        !purchaseOrdersQuery.isLoading &&
        !returnsQuery.isLoading &&
        !tasksQuery.isLoading &&
        !taskEventsQuery.isLoading &&
        !usersQuery.isLoading

    const eventSource: any = {
        foreign_id: orderId,
        foreign_table: 'orders',
    }

    const tabs: any = {
        middle: {
            tasks: tasks,
            returns: returns,
            'purchase orders': purchaseOrders,
        },
        right: {},
    }

    const eventsLoaded = !privateEventsQuery.isLoading && !taskEventsQuery.isLoading && !emailsQuery.isLoading

    const events = [orderEvents, taskEvents, ...createOrderNotesArray(order?.note || '', users), emails]
        .flat()
        .filter((v) => v)

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }

    useEffect(() => {
        if (!adminEventLogged && allLoaded) {
            if (user && typeof orderId === 'string') {
                createPrivateEvent.mutate(
                    {
                        id: parseInt(orderId),
                        user_id: user.id,
                        privateEventUrl: '/v1/orderEventsPrivate',
                    },
                    {
                        onSuccess: () => {
                            setAdminEventLogged(true)
                        },
                    }
                )
            }
        }
    }, [allLoaded])

    function onEmailSubmit(): any {
        queryClient.invalidateQueries({ queryKey: orderQueryKeys.orders.emails._def })
        queryClient.invalidateQueries({ queryKey: orderQueryKeys.orders.attachments._def })
    }

    return (
        <div className='flex flex-col gap-[8px] text-text1 dark:text-darktext1 h-[89vh]'>
            {orderId && (
                <OrderHeader
                    orderId={orderId}
                    orderEventsPrivate={orderEventsPrivate}
                    order={order}
                    tasks={tasks?.filter((t: any) => !!(t as TaskEditInit).id) as TaskEditInit[]}
                    addNewTask={toggleCreateModal}
                    showActivity={showActivity}
                    setShowActivity={setShowActivity}
                    eventSource={eventSource}
                    onEmailSubmit={onEmailSubmit}
                    origin='order'
                />
            )}

            {/* Info columns */}
            {orderDetailQuery.isSuccess && order?.id && (
                <div className='grid lg:grid-cols-3 grid-cols-2 gap-[4px] items-start justify-start h-full min-h-1'>
                    {/* left column */}
                    <div
                        className={cn(
                            'grid grid-cols-subgrid items-start gap-[8px] basis-[calc(33.3%-32px)] grow min-w-[400px] p-1 max-h-full overflow-scroll',
                            !showActivity && 'col-span-2'
                        )}
                    >
                        <div className='flex flex-col gap-2'>
                            {orderDetailQuery.isSuccess && order?.id && (
                                <React.Fragment>
                                    {fulfillmentOrdersQuery.isSuccess && (
                                        <UnfulfilledItems order={order} fulfillmentOrders={fulfillmentOrders} />
                                    )}
                                    <FulfilledItems order={order} />
                                    <RemovedItems order={order} fulfillmentOrders={fulfillmentOrders} />
                                    {fulfillmentOrdersQuery.isSuccess && (
                                        <PaymentStatus order={order} fulfillmentOrders={fulfillmentOrders} />
                                    )}
                                    <InvoicePaymentStatus
                                        custom_invoice_paid={(order as any).custom_invoice_paid}
                                        order_id={order.id.toString()}
                                    />
                                </React.Fragment>
                            )}
                            {order?.note_attributes.length > 0 && (
                                <div className='grid bg-bg1 dark:bg-darkbg1 shadow-small rounded dark:text-darktext1 [&>*]:p-[16px] [&>*]:border-b-[1px] [&>*]:dark:border-darkgrey [&>:last-child]:border-0'>
                                    <div>
                                        <h2 className='text-[20px] font-bold mb-[4px]'>Additional Details</h2>
                                        {order.note_attributes.map((attr, i) => {
                                            if (attr.name === 'shipping_estimate') {
                                                return (
                                                    <div className='mb-[8px]' key={`order__note-attribute-${i}`}>
                                                        <h3 className='capitalize font-semibold text-[16px] mb-[4px]'>
                                                            {attr.name.replace('_', ' ')}
                                                        </h3>
                                                        {JSON.stringify(attr.value)
                                                            .split('\n')
                                                            .map((line, index) => (
                                                                <p
                                                                    key={index}
                                                                    className='mb-[8px] text-[14px] break-all'
                                                                >
                                                                    {line.split(':').join(': ')}
                                                                </p>
                                                            ))}
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className='mb-[8px]' key={`order__note-attribute-${i}`}>
                                                        <h3 className='capitalize font-semibold text-[16px] mb-[4px]'>
                                                            {attr.name.replace('_', ' ')}
                                                        </h3>
                                                        {JSON.stringify(attr.value)
                                                            .split('\n')
                                                            .map((line, index) => (
                                                                <p
                                                                    key={index}
                                                                    className='mb-[8px] text-[14px] break-all'
                                                                >
                                                                    {line}
                                                                </p>
                                                            ))}
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            )}
                            {order?.id && <ManufacturerOrderNumbers order_id={order.id.toString()} />}
                        </div>

                        <div className='flex flex-col gap-2'>
                            {/* {orderDetailQuery.isSuccess && order?.id && (
                                <Customer
                                    key={order?.customer.id}
                                    order={order}
                                    eventSource={eventSource}
                                    onEmailSubmit={onEmailSubmit}
                                />
                            )} */}
                            {orderDetailQuery.isSuccess && order?.id && <FraudAnalysis order={order} />}
                            <Tags tags={orderTags} />
                        </div>
                    </div>

                    <div className='flex flex-wrap gap-[8px] basis-[calc(66.6%-32px)] grow items-start justify-start pr-[2px]  max-h-full overflow-scroll'>
                        {/* middle column  */}
                        <div className='flex flex-col gap-x-[8px] basis-[calc(33%-32px)] min-w-[400px] grow shrink'>
                            <div className='relative top-[1px] flex gap-[8px] ml-[8px] border-b-darkgrey'>
                                {Object.keys(tabs.middle).map((tab, index) => {
                                    const count =
                                        tab === 'tasks'
                                            ? order?.task_count || tabs.middle[tab]?.length || 0
                                            : tabs.middle[tab]?.length || 0
                                    return (
                                        <button
                                            key={index}
                                            className={`border-black dark:border-darkgrey border-[1px] border-b-0 ${
                                                selectedTab === tab
                                                    ? 'bg-white dark:bg-darkness z-index-1'
                                                    : '!border-transparent'
                                            } rounded-t px-[4px] capitalize`}
                                            onClick={() => setSelectedTab(tab)}
                                        >
                                            {tab} ({tabs.middle[tab]?.length || 0})
                                        </button>
                                    )
                                })}
                            </div>
                            <div className='grid gap-[8px] border-[1px] border-black dark:border-darkgrey rounded p-[8px] h-full'>
                                {selectedTab === 'tasks' && (
                                    <div className='flex flex-col gap-[8px]'>
                                        {order?.task_count > 0 &&
                                            tasksQuery.isLoading &&
                                            new Array(order?.task_count).fill('').map((task: any, i: number) => (
                                                <React.Fragment key={task.id}>
                                                    <TaskEditCreateModalV2 createEvent={false} taskId={''} />
                                                </React.Fragment>
                                            ))}
                                        {tasks?.length > 0 &&
                                            tasks.map((task: any, i: number) => (
                                                <React.Fragment key={task.id}>
                                                    <TaskEditCreateModalV2
                                                        createEvent={true}
                                                        taskId={task.id.toString()}
                                                    />
                                                </React.Fragment>
                                            ))}
                                        <div className='flex justify-start items-center gap-[8px]'>
                                            <Button
                                                variant={'outline'}
                                                size={'sm'}
                                                onClick={() => toggleCreateModal()}
                                                className='flex gap-1 items-center'
                                            >
                                                <p> New Task</p>
                                                <p className='mb-[2px]'>+</p>
                                            </Button>
                                        </div>
                                    </div>
                                )}

                                {selectedTab === 'returns' && !returnsQuery.isLoading && (
                                    <ReturnsBlock order={order} alwaysOpen={true} propReturns={returns} />
                                )}
                                {selectedTab === 'purchase orders' && !purchaseOrdersQuery.isLoading && (
                                    <div className='w-[100%]'>
                                        <PurchaseOrdersList purchaseOrders={purchaseOrders} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* right column */}
                    {order?.id && showActivity && (
                        <div className='flex flex-col gap-[8px] basis-[calc(33%-32px)] min-w-[400px] p-1 grow shrink max-h-full overflow-x-scroll col-span-full lg:col-span-1'>
                            <Events events={events} tasks={tasks} order={order} eventsLoaded={eventsLoaded} />
                        </div>
                    )}
                </div>
            )}
            {!orderDetailQuery.isSuccess && !order?.id && (
                <OrderHubSkeletonBody tabs={tabs} events={events} showActivity={showActivity} />
            )}
            {showCreateModal && (
                <CreateTaskModal
                    prePopulatedValues={{
                        HTMLDateString: HTMLDateInputFormatter(addBusinessDays(new Date(), 1)),
                        department_id: departments?.find((department: any) => department.title === 'Customer Service')
                            .id,
                        priority: 'Low',
                        status: 'To Do',
                        associations: [
                            {
                                resource_name: 'user',
                                readable_name: `${user.firstName} ${user.lastName}`,
                                resource_id: user.id,
                            },
                            {
                                resource_name: 'order',
                                readable_name: `${order?.name}`,
                                resource_id: order.id,
                            },
                        ],
                    }}
                    closeModal={toggleCreateModal}
                />
            )}
        </div>
    )
}
