import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { LuInfo } from 'react-icons/lu'
import { useAllUsersQuery } from 'users/api/useQueries'
import { formatDateToLocale } from '../../helpers'
import { UserInit } from '../../users/users.types'
import { PrivateEventType } from '../tasks.types'

export default function PrivateEvent({ events, setViewModal }: { events: PrivateEventType[]; setViewModal: any }) {
    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []
    if (!events || (events && events?.length === 0)) {
        return (
            <>
                <div className='flex '>
                    <div className='px-[2px] font-bold text-[12px] min-w-[100px] uppercase'>Last Viewed - </div>
                    <button className='px-[6px]  font-normal text-center cursor-pointer' onClick={() => {}}>
                        <FetchingSpinner isFetching={events ? false : true} />
                    </button>
                </div>
            </>
        )
    }

    const latestEvent = events[1] ? events[1] : events[0]
    const eventDate = formatDateToLocale(latestEvent.created_at)
    const date = eventDate.split(' ').slice(0, 2).join(' ')
    const time = eventDate.split(' ').slice(3).join(' ')
    const user =
        users?.find((u: any) => parseInt(latestEvent.user_id) === parseInt(u.id)) ||
        users?.find((u: any) => latestEvent.user_id === u.user_id)

    const day = new Date(Date.parse(latestEvent.created_at)).toDateString().split(' ')[0]
    return (
        <>
            <div className='flex '>
                <div className='px-[2px] font-bold text-[12px] uppercase'>
                    {latestEvent?.event_type || 'Last Viewed'} - {user?.user_id || 'unknown'} - {day + ' '}
                    {date} {time}
                </div>
                <button className='px-[6px]  font-normal text-center cursor-pointer' onClick={() => setViewModal(true)}>
                    <LuInfo></LuInfo>
                </button>
            </div>
        </>
    )
}
