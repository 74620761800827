import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const orders = createQueryKeys('orders', {
    // list: (filters: any) => ({
    //     queryKey: [filters],
    //     queryFn: () => service.getTasks(filters),
    // }),
    detail: (orderId: string | undefined) => ({
        queryKey: [orderId],
        queryFn: () => service.getOrder(orderId),
        contextQueries: {
            events: () => ({
                queryKey: [orderId],
                queryFn: () => service.getOrderEventsPrivate(orderId),
            }),
        },
    }),

    timeLineEvents: (orderId: string | undefined) => ({
        queryKey: [orderId],
        queryFn: () => service.getShopifyTimelineEvents(orderId),
    }),
    orderTaskEvents: (taskIds: string[]) => ({
        queryKey: [taskIds],
        queryFn: () => service.getOrderTaskEvents(taskIds),
    }),

    emails: (orderId: string | undefined, purchaseOrderIds: number[]) => ({
        queryKey: [orderId, purchaseOrderIds],
        queryFn: () => service.getEmails(orderId, purchaseOrderIds),
    }),
    attachments: (orderId: string | undefined, table: string) => ({
        queryKey: [orderId, table],
        queryFn: () => service.getEmailAttachments(orderId, table),
    }),

    // options: (type: 'estimates' | 'types') => ({
    //     queryKey: [type],
    //     queryFn: () => service.getTaskOptions(type),
    // }),
})
export const fulfillmentOrders = createQueryKeys('fulfillmentOrders', {
    list: (orderId: string | undefined) => ({
        queryKey: [orderId],
        queryFn: () => service.getFulfillmentOrders(orderId),
    }),
    // detail: (task_id: string) => ({
    //     queryKey: [task_id],
    //     queryFn: () => service.getTask(task_id),
    //     contextQueries: {
    //         events: () => ({
    //             queryKey: [task_id],
    //             queryFn: () => service.getTaskEvents(task_id),
    //         }),
    //     },
    // }),
    // options: (type: 'estimates' | 'types') => ({
    //     queryKey: [type],
    //     queryFn: () => service.getTaskOptions(type),
    // }),
})
export const purchaseOrders = createQueryKeys('purchaseOrders', {
    list: (orderId: string | undefined) => ({
        queryKey: [orderId],
        queryFn: () => service.getPurchaseOrders(orderId),
    }),
})
export const returns = createQueryKeys('returns', {
    list: (orderNumber: number | undefined) => ({
        queryKey: [orderNumber],
        queryFn: () => service.getReturns(orderNumber),
    }),
})

export default mergeQueryKeys(orders, fulfillmentOrders, purchaseOrders, returns)
