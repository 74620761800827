import { FaRegCalendarCheck } from 'react-icons/fa'
import { MdLocalShipping, MdLocationPin } from 'react-icons/md'
import { DayMap, MonthMap, dayMap, formatDateToLocale, locationMap, monthMap } from '../../../helpers'
import { DeliveryStatusMap, OrderInit, deliveryStatusMap } from '../../orders.types'
import BottomItemInfo from './bottomItemInfo'

type FulfilledItemsProps = {
    order: OrderInit
}

export default function FulfilledItems({ order }: FulfilledItemsProps) {
    const bigTextSize = 'text-[20px]'
    const mediumTextSize = 'text-[14px]'
    const smallTextSize = 'text-[12px]'

    return (
        <>
            {order.fulfillments?.map(
                (f) =>
                    f.status !== 'cancelled' && (
                        <div
                            key={f.id}
                            className='grid gap-[8px] bg-white dark:bg-darkaccent p-[16px] shadow-small rounded dark:text-offwhite'
                        >
                            <div className='flex justify-between'>
                                <div className={`flex items-center gap-[8px] ${mediumTextSize}`}>
                                    <svg
                                        className='w-[26px] h-[auto] fill-[#16a679] dark:fill-[rgb(150,255,175)] border-[3px] border-[#a1edd0] dark:border-[rgba(100,225,150,0.3)] rounded-full'
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 17 17'
                                        role='img'
                                        focusable='false'
                                        aria-hidden='true'
                                    >
                                        <path d='M8.5 17a8.36 8.36 0 1 1 8.35-8.36A8.35 8.35 0 0 1 8.5 17zm0-15a6.69 6.69 0 1 0 6.68 6.68A6.68 6.68 0 0 0 8.5 2z' />
                                        <path d='M7.66 11.09a.82.82 0 0 1-.59-.24L5.4 9.18A.84.84 0 0 1 5.45 8a.82.82 0 0 1 1.13 0l1.08 1.08 2.75-2.75a.83.83 0 0 1 1.18 1.18l-3.34 3.34a.82.82 0 0 1-.59.24z' />
                                    </svg>
                                    <h2 className={`font-bold ${bigTextSize}`}>Fulfilled ({f.line_items.length})</h2>
                                    <span className='text-darkgrey dark:text-grey'>{f.name.replace('.', '-F')}</span>
                                </div>
                            </div>
                            <div className='grid grid-rows-[40px] items-center gap-[8px]'>
                                <div className={`flex gap-[10px] items-center ${mediumTextSize}`}>
                                    <MdLocationPin className='h-[25px] w-[auto]' />
                                    <div>
                                        <h3 className={`text-darkgrey dark:text-grey ${smallTextSize}`}>Location</h3>
                                        <span>{locationMap[f.location_id]}</span>
                                    </div>
                                </div>
                                <div className={`flex gap-[10px] items-center ${mediumTextSize}`}>
                                    <FaRegCalendarCheck className='w-[20px] h-[auto] justify-self-center' />
                                    <div>
                                        <h3 className={`text-darkgrey dark:text-grey ${smallTextSize}`}>Fulfilled</h3>
                                        <span>
                                            {new Date(Date.parse(f.created_at))
                                                .toDateString()
                                                .split(' ')
                                                .map((dateItem: string) =>
                                                    dayMap[dateItem as keyof DayMap]
                                                        ? `${dayMap[dateItem as keyof DayMap]}, `
                                                        : monthMap[dateItem as keyof MonthMap]
                                                          ? `${monthMap[dateItem as keyof MonthMap]}`
                                                          : Number(dateItem) < 32
                                                            ? `${dateItem},`
                                                            : dateItem
                                                )
                                                .join(' ')}
                                        </span>
                                    </div>
                                </div>
                                {f.tracking_numbers?.length > 0 && (
                                    <div className={`flex gap-[10px] items-start ${mediumTextSize}`}>
                                        <div className='pt-[6px]'>
                                            <MdLocalShipping className='w-[25px] h-[auto]' />
                                        </div>
                                        <div>
                                            <div className='flex gap-[8px]'>
                                                <h3 className={`text-darkgrey dark:text-grey ${smallTextSize}`}>
                                                    {f.tracking_company === 'Other'
                                                        ? 'Other tracking'
                                                        : f.tracking_company}
                                                </h3>
                                                {f.status === 'success' && f.shipment_status && (
                                                    <span
                                                        className={`${
                                                            deliveryStatusMap[
                                                                f.shipment_status as keyof DeliveryStatusMap
                                                            ] || 'bg-grey'
                                                        } dark:text-black font-semibold rounded-full px-[8px] text-[10px] my-auto capitalize`}
                                                    >
                                                        {f.shipment_status.replaceAll('_', ' ')} -{' '}
                                                        {formatDateToLocale(f.updated_at)}
                                                    </span>
                                                )}
                                            </div>
                                            {f.tracking_numbers && (
                                                <div className='flex flex-col'>
                                                    {f.tracking_numbers.map((t: string) => {
                                                        const tIndex = f.tracking_numbers.indexOf(t)
                                                        const associatedURL = f.tracking_urls.find((url) =>
                                                            url.includes(t.trim())
                                                        )
                                                        return (
                                                            <span
                                                                key={t}
                                                                className='hover:underline hover:text-blue dark:hover:text-accent cursor-pointer'
                                                                onClick={() => {
                                                                    if (associatedURL)
                                                                        return window.open(associatedURL, '_blank')
                                                                    if (!f.tracking_urls[tIndex].includes('ups.com')) {
                                                                        navigator.clipboard.writeText(t)
                                                                        setTimeout(() => {
                                                                            alert(
                                                                                'Tracking number copied: ' +
                                                                                    t +
                                                                                    '\n\nYou can right-click and paste the tracking number into the tracking website.\n\nClick OK to continue to the tracking website. \n(If the next page does not appear, it may have been blocked by your popup blocker. Try again.)'
                                                                            )
                                                                            window.open(
                                                                                f.tracking_urls[tIndex],
                                                                                '_blank'
                                                                            )
                                                                        }, 0)
                                                                    } else {
                                                                        window.open(f.tracking_url, '_blank')
                                                                    }
                                                                }}
                                                            >
                                                                {t}
                                                            </span>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='grid [&>div]:border-b-[1px] dark:[&>div]:border-darkgrey [&>:last-child]:border-0'>
                                {f.line_items.map((item) => {
                                    return <BottomItemInfo key={item.id} item={item} order={order} />
                                })}
                            </div>
                        </div>
                    )
            )}
        </>
    )
}
