import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import { differenceInCalendarDays } from 'date-fns'
import { cn } from 'helpers'
import queryKeys from 'tasksV2/api/queryKeys'
import { useUpdateTask } from 'tasksV2/api/useQueries'
import { TASK_STATUSES } from 'tasksV2/constants/tasks'

export default function TaskStatus({
    taskId,
    status,
    dueAt,
    mutation,
    className,
}: {
    taskId: number
    dueAt: string
    status: string
    mutation?: any
    className?: string
}) {
    const queryClient = useQueryClient()
    const updateTaskMutation = useUpdateTask(taskId.toString())
    const overdue = differenceInCalendarDays(new Date(), new Date(dueAt)) != 0 && status != 'Completed'
    const handleChange = ({ target }: { target: HTMLSelectElement }) => {
        queryClient.setQueryData(queryKeys.tasks.detail(taskId.toString()).queryKey, (oldData: any) => {
            if (oldData) {
                return {
                    ...oldData,
                    task: { ...oldData.task, status: target.value },
                }
            }
        })
        if (mutation) {
            mutation.mutate({
                id: taskId,
                status: target.value,
            })
        } else {
            updateTaskMutation.mutate({
                id: taskId,
                status: target.value,
            })
        }
    }
    return (
        <div className='flex gap-1 items-center'>
            <select
                className={cn(
                    'border border-lightgrey dark:border-darkgrey  rounded  w-full dark:bg-darkbg1 cursor-pointer',
                    className
                )}
                id='task_estimate_id'
                name='task_estimate_id'
                value={status}
                onClick={(e) => {
                    e.stopPropagation()
                }}
                onChange={handleChange}
            >
                <option value=''>None</option>
                {TASK_STATUSES.map((status: any) => (
                    <option key={`${taskId}-${status}`} value={status}>
                        {status}
                    </option>
                ))}
            </select>
            {overdue && (
                <div className='group relative'>
                    <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center' />
                    <div className='hidden group-hover:flex border absolute top-[100%] right-[-40px] w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[100] '>
                        Task Overdue
                    </div>
                </div>
            )}
        </div>
    )
}
