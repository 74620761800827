import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const draftOrders = createQueryKeys('draftOrders', {
    detail: (orderId: string | undefined) => ({
        queryKey: [orderId],
        queryFn: () => service.getDraftOrder(orderId),
        contextQueries: {
            events: () => ({
                queryKey: [orderId],
                queryFn: () => service.getOrderEventsPrivate(orderId),
            }),
        },
    }),

    timeLineEvents: (orderId: string | undefined) => ({
        queryKey: [orderId],
        queryFn: () => service.getShopifyTimelineEvents(orderId),
    }),
    orderTaskEvents: (taskIds: string[]) => ({
        queryKey: [taskIds],
        queryFn: () => service.getOrderTaskEvents(taskIds),
    }),

    emails: (table: string, orderId: string | undefined, purchaseOrderIds: number[]) => ({
        queryKey: [table, orderId, purchaseOrderIds],
        queryFn: () => service.getEmails(table, orderId, purchaseOrderIds),
    }),
})
export const fulfillmentOrders = createQueryKeys('fulfillmentOrders', {
    list: (orderId: string | undefined) => ({
        queryKey: [orderId],
        queryFn: () => service.getFulfillmentOrders(orderId),
    }),
})
export const purchaseOrders = createQueryKeys('purchaseOrders', {
    list: (orderId: string | undefined) => ({
        queryKey: [orderId],
        queryFn: () => service.getPurchaseOrders(orderId),
    }),
})
export const returns = createQueryKeys('returns', {
    list: (orderNumber: number | undefined) => ({
        queryKey: [orderNumber],
        queryFn: () => service.getReturns(orderNumber),
    }),
})

export default mergeQueryKeys(draftOrders, fulfillmentOrders, purchaseOrders, returns)
