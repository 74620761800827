import { keepPreviousData, useQueryClient } from '@tanstack/react-query'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { NoResults } from 'custom_components/NoResults'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import TaskEditCreateModalV2 from 'tasks/components/TaskEditCreateModalV2'
import * as taskQueryKeys from 'tasksV2/api/queryKeys'
import { useGetAllDepartmentConfigs, useGetTasks } from 'tasksV2/api/useQueries'
import { TASK_PRIORITIES, TASK_STATUSES } from 'tasksV2/constants/tasks'
import { FilterBarSelectorButton } from '../../../custom_components/component_Basics/FilterBarSelectorButton'
import { cn, useDebounce } from '../../../helpers'
import FilterToolBar from '../FilterToolBar'
import { TbAntennaBars1, TbAntennaBars3, TbAntennaBars4, TbAntennaBars5 } from 'react-icons/tb'
import { useSelector } from 'react-redux'

export default function TaskListV2Company({}: {}) {
    const user = useSelector<any, any>((state) => state.user)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const urlParamTaskId = searchParams.get('task_id')
    const searchParam = searchParams.get('search') || ''
    const [search, setSearch] = useState(urlParamTaskId || '')
    const [initRender, setInitRender] = useState(true)
    const debouncedSearch = useDebounce(search, 300)
    const companyId = searchParams.get('company_id')
    const prioritiesString = searchParams.get('priorities') || ''
    const statusString = searchParams.get('statuses') || ''
    const typesString = searchParams.get('types') || ''
    const queryClient = useQueryClient()

    const typeValues = decodeURIComponent(typesString)
        .split(',')
        .filter((v) => v)
    const statusValues = decodeURIComponent(statusString)
        .split(',')
        .filter((v) => v)
    const priorityValues = decodeURIComponent(prioritiesString)
        .split(',')
        .filter((v) => v)

    useEffect(() => {
        if (search === '' && urlParamTaskId) {
            navigate('/procurement')
        }
    }, [search])

    useEffect(() => {
        debouncedSearch ? searchParams.set('search', debouncedSearch) : searchParams.delete('search')
        setSearchParams(searchParams, { replace: true })
    }, [debouncedSearch])
    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
    }, [])
    useEffect(() => {
        if (!initRender) {
            setSearch(searchParam)
        }
    }, [searchParam])

    const departmentConfigQuery = useGetAllDepartmentConfigs(user.id)
    const { departments } = departmentConfigQuery?.data || {}

    const types = departments?.find((department: any) => department.title === 'Procurement').types.flat()
    const typeOptions = types?.map((type: any) => {
        return {
            label: type.value,
            value: type.id.toString(),
            icon: undefined,
        }
    })

    const priorityIcons = [TbAntennaBars1, TbAntennaBars3, TbAntennaBars4, TbAntennaBars5]
    const priorityOptions = TASK_PRIORITIES?.map((priority: any, index) => {
        return {
            label: priority,
            value: priority,
            icon: priorityIcons[index],
        }
    })

    const statusOptions = TASK_STATUSES?.map((status: any) => {
        return {
            label: status,
            value: status,
            icon: undefined,
        }
    })
    const taskFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: statusOptions,
                title: 'Status',
                field: 'statuses',
                values: statusValues,
                searchToggle: false,
                editSearchParams: true,
            },
            {
                component: FilterBarSelectorButton,
                options: priorityOptions,
                title: 'Priority',
                field: 'priorities',
                values: priorityValues,
                searchToggle: false,
                editSearchParams: true,
            },
            {
                component: FilterBarSelectorButton,
                options: typeOptions,
                title: 'Type',
                field: 'types',
                values: typeValues,
                searchToggle: false,
                editSearchParams: true,
            },
        ],
        setFunction: () => {},
        resetFunction: () => {
            setSearchParams((prev: any) => {
                prev.delete('statuses')
                return prev
            })
        },
    }
    const tasksQueryV2 = useGetTasks(
        {
            resourceTable: 'company_tasks',
            resourceId: companyId,
            statuses: statusValues,
            search: debouncedSearch,
            priorities: priorityValues,
            typeIds: typeValues,
            limit: 300,
        },
        keepPreviousData
    )
    const { tasks: tasksV2 } = tasksQueryV2?.data || {}
    tasksV2?.forEach((task: any) => {
        queryClient.setQueryData(taskQueryKeys.tasks.detail(task.id.toString() || '').queryKey, (oldData: any) => {
            if (!oldData) {
                return { success: true, task }
            }
        })
    })

    const dueAtSort = (a: any, b: any) => {
        if (new Date(a.due_at).getTime() < new Date(b.due_at).getTime()) {
            return -1
        }
        return 1
    }

    const dateSortedTasks = tasksV2?.length ? [...tasksV2].sort(dueAtSort) : []

    // function handlePageIncrease() {
    //     if (tasksV2?.length === 50) {
    //         const newPage = (typeof page === 'string' ? parseInt(page) : page) + 1
    //         setSearchParams((prev: any) => {
    //             prev.set('page', newPage.toString())
    //             return prev
    //         })
    //     }
    // }
    // function handlePageDecrease() {
    //     if (typeof page === 'string' ? parseInt(page) : page > 1) {
    //         const newPage = (typeof page === 'string' ? parseInt(page) : page) - 1
    //         setSearchParams((prev: URLSearchParams) => {
    //             prev.set('page', newPage.toString())
    //             return prev
    //         })
    //     }
    // }
    // function handleFirstPage() {
    //     if (typeof page === 'string' ? parseInt(page) : page > 1) {
    //         setSearchParams((prev: URLSearchParams) => {
    //             prev.delete('after')
    //             prev.delete('before')
    //             prev.set('page', '1')
    //             return prev
    //         })
    //     }
    // }

    return (
        <>
            <div className={cn('sticky z-40 bg-bg1 dark:bg-darkbg1 top-[33px]')}>
                <FilterToolBar search={search} setSearch={setSearch} filterBarObject={taskFilterBarObject}>
                    {companyId &&
                        taskFilterBarObject.params.length &&
                        taskFilterBarObject.params.map((param: any) => {
                            return (
                                <React.Fragment>
                                    <param.component
                                        key={param.field}
                                        searchToggle={param.searchToggle}
                                        title={param.title}
                                        field={param.field}
                                        options={param.options}
                                        filterValues={param.values}
                                        setFilterValues={taskFilterBarObject.setFunction}
                                        editSearchParams={param.editSearchParams}
                                    />
                                </React.Fragment>
                            )
                        })}
                </FilterToolBar>
            </div>
            <div
                className={cn(
                    'sticky z-[50] px-2 grid grid-cols-[45px_30px_100px_1.2fr_.8fr_.5fr_28px_1fr_1fr] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness top-[72px]'
                )}
            >
                <div></div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'>Id</div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'>Associations</div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'></div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'>Type</div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'>Status</div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'></div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'></div>
                <div className='absolute right-4 top-1/4'>
                    <FetchingSpinner isFetching={tasksQueryV2.isLoading || tasksQueryV2.isLoading} />
                </div>
            </div>
            <div className='p-0 pl-0 overflow-auto min-h-[50vh]'>
                <div className='mb-[8px] w-full flex gap-[16px]'></div>

                <div className='flex flex-col gap-[8px] overflow-visible'>
                    {!tasksQueryV2.isLoading &&
                        dateSortedTasks
                            ?.filter((task: any) => task.status !== 'Completed')
                            .map((task: any) => (
                                <React.Fragment key={task.id.toString() + 'procurementTask'}>
                                    <TaskEditCreateModalV2 taskId={task.id.toString()} createEvent={false} />
                                </React.Fragment>
                            ))}
                </div>
                <div className='flex flex-col gap-[8px] overflow-visible'>
                    {!tasksQueryV2.isLoading &&
                        dateSortedTasks
                            ?.filter((task: any) => task.status === 'Completed')
                            .map((task: any) => (
                                <React.Fragment key={task.id.toString() + 'procurementTask'}>
                                    <TaskEditCreateModalV2 taskId={task.id.toString()} createEvent={false} />
                                </React.Fragment>
                            ))}
                </div>

                {!tasksQueryV2.isFetching && tasksV2?.length === 0 && (
                    <NoResults margin='mx-auto' content={`No Results For Selected Filters`} />
                )}
                {/* <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                    <>
                        <button
                            disabled={page === 1}
                            className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                handlePageDecrease()
                            }}
                        >
                            <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </button>
                    </>
                    <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                    <button
                        className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                        onClick={() => {
                            handlePageIncrease()
                        }}
                        disabled={tasksV2?.length < 25}
                    >
                        <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                    </button>
                </div> */}
            </div>
        </>
    )
}
