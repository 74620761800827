import { CheckIcon } from '@radix-ui/react-icons'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { addBusinessDays } from 'date-fns'
import { useContext, useEffect, useState } from 'react'
import { FaBars, FaBuilding, FaClipboardList, FaSyncAlt, FaTasks } from 'react-icons/fa'
import { TbAntennaBars1, TbAntennaBars3, TbAntennaBars4, TbAntennaBars5 } from 'react-icons/tb'
import { useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import { useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'
import { TASK_PRIORITIES, TASK_STATUSES } from 'tasksV2/constants/tasks'
import { HTMLDateInputFormatter } from 'tasksV2/helpers'
import { V2TaskCalendar } from 'tasksV2/tasks/components/V2TaskCalendar'
import CreateTaskModal from 'tasksV2/tasks/modals/CreateTaskModal'
import { useAllUsersQuery } from 'users/api/useQueries'
import { UserInit } from 'users/users.types'
import RefreshButton from '../custom_components/RefreshButton'
import { Button } from '../custom_components/component_Basics/Button'
import { cn, vFetch } from '../helpers'
import MoreActions from '../processingTimes/components/MoreActions'
import { UserSelectedCompanyFilterValues } from './Procurements.types'
import { procurementQueries } from './api/procurementQueryKeys'
import { useCompanyDetailQuery } from './api/useQueries'
import CategoryList from './components/lists/CategoryList'
import CompanyListV2 from './components/lists/CompanyListV2'
import CompanySalesList from './components/lists/CompanySalesList'
import TaskListV2 from './components/lists/TaskListV2'
import CompanyModalV2 from './components/modals/CompanyModalV2'
import CreateCategory from './components/modals/CreateCategory'
import CreateCompanyModalV2 from './components/modals/CreateCompanyModalV2'
import MissingVendorsModal from './components/modals/MissingVendorsModal'
import ParentCompanyModal from './components/modals/ParentCompanyModal'
import { getDateToday } from './constants'
import { CompanyProvider, ProcurementContext, ProcurementContextType } from './helpers'

export default function ProcurementCompanies() {
    const user = useSelector<any, any>((state) => state.user)
    const { view, activeModal, toggleModal, handleChangeView, categories, selectedCompany, setSelectedCompany } =
        useContext<ProcurementContextType>(ProcurementContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const tab = searchParams.get('tab')
    const googleCode = searchParams.get('code')
    const scope = searchParams.get('scope')
    const urlParamTaskId = searchParams.get('task_id')
    const urlParamCompanyId = searchParams.get('company_id')
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const [tokens, setTokens] = useState(null)
    const [search, setSearch] = useState('')

    const departmentConfigQuery = useGetAllDepartmentConfigs(user.id)
    const [taskDueDate, setTaskDueDate] = useState<Date | undefined>(getDateToday())

    const prioritiesString = searchParams.get('priorities') || ''
    const assignedToString = searchParams.get('assigned_to') || ''
    const statusString = searchParams.get('statuses') || ''
    const typesString = searchParams.get('types') || ''

    const stringGroupFilterValues = localStorage.getItem('groupFilterValues')
    const [groupFilterValues, setGroupFilterValues] = useState<any>(
        stringGroupFilterValues ? JSON.parse(stringGroupFilterValues) : {}
    )

    const priorityValues = decodeURIComponent(prioritiesString)
        .split(',')
        .filter((v) => v)
    const statusValues = decodeURIComponent(statusString)
        .split(',')
        .filter((v) => v)
    const typeValues = decodeURIComponent(typesString)
        .split(',')
        .filter((v) => v)

    const assignedToValues = decodeURIComponent(assignedToString)
        .split(',')
        .filter((v) => v)

    const priorityIcons = [TbAntennaBars1, TbAntennaBars3, TbAntennaBars4, TbAntennaBars5]
    const priorityOptions = TASK_PRIORITIES?.map((priority: any, index) => {
        return {
            label: priority,
            value: priority,
            icon: priorityIcons[index],
        }
    })
    const statusOptions = TASK_STATUSES?.map((status: any) => {
        return {
            label: status,
            value: status,
            icon: undefined,
        }
    })

    const selectionOptions = [
        { title: 'Completed', id: 'completed' },
        { title: 'Overdue', id: 'overdue' },
    ]
    const userFilterOptions = [
        { title: 'Myself', id: 'showMyself' },
        { title: 'All', id: 'showAll' },
    ]
    const handleFilterValuesEdit = (newValue: any) => {
        if (Object.keys(newValue)?.length) {
            searchParams.set('page', '1')
            setSearchParams(searchParams)
            localStorage.setItem('groupFilterValues', JSON.stringify(newValue))
        }
    }
    const { departments } = departmentConfigQuery?.data || {}

    const types = departments
        ?.map((department: any) => {
            return department.types.flat()
        })
        .flat()
    const typeOptions = types?.map((type: any) => {
        return {
            label: type.value,
            value: type.id.toString(),
            icon: undefined,
        }
    })
    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []
    const activeUsers = users?.filter((user) => !user.roles.includes('suspended'))
    const userOptions = activeUsers.map((user) => {
        return {
            label: user.user_id,
            value: user.id.toString(),
            icon: undefined,
        }
    })
    const filters = {
        userIds: assignedToValues.length ? assignedToValues : groupFilterValues['showMyself'] ? [user.id] : undefined,
        departmentIds: [departments?.find((department: any) => department.title === 'Procurement')?.id],
        // departmentIds: departmentValues,
        typeIds: typeValues,
        priorities: priorityValues,
        statuses:
            statusValues.length > 0
                ? statusValues
                : groupFilterValues['completed']
                  ? TASK_STATUSES
                  : [...TASK_STATUSES.filter((status) => status != 'Completed')],
        date: taskDueDate,
        overdue: groupFilterValues['overdue'] ? true : undefined,
        limit: 25,
    }
    const lastViewedCompany = localStorage.getItem('lastViewedCompany') || undefined

    const [userSelectedCompanyFilterValues, setUserSelectedCompanyFilterValues] =
        useState<UserSelectedCompanyFilterValues>({
            lifecycle_statuses: [],
            lead_types: [],
            users: [user.id],
        })

    const companyDetailQuery = useCompanyDetailQuery(urlParamCompanyId ? parseInt(urlParamCompanyId) : undefined)
    const { companies: company } = companyDetailQuery?.data || {}
    useEffect(() => {
        if (company) {
            toggleModal('company', company[0])
        }
    }, [company])

    function linkGmailOAuth() {
        if (!googleCode && !scope) {
            vFetch(`/v2/google/auth?user_id=${user.id}`, {
                cb: (res: any) => {
                    if (res.success) setTokens(res.tokens)
                    else if (res.authorizationURL) window.location.replace(res.authorizationURL)
                },
            })
        }
    }

    const options = [
        {
            displayText: `Sync Gmail Account`,
            icon: FaSyncAlt,
            action: linkGmailOAuth,
        },
        {
            displayText: `Missing Vendors List`,
            icon: FaSyncAlt,
            action: () => toggleModal('missing-vendors'),
        },
    ]

    useEffect(() => {
        if (urlParamTaskId) {
            setSearch(urlParamTaskId)
            handleChangeView('tasks')
            setSearchParams((prev: any) => {
                prev.set('tab', 'tasks')
                return prev
            })
        }
    }, [urlParamTaskId])

    useEffect(() => {
        if (googleCode && scope && !tokens) {
            const { REACT_APP_MODE, REACT_APP_LOCAL_API_URL, REACT_APP_PRODUCTION_URL, REACT_APP_DEVELOPEMENT_URL } =
                process.env
            const API_URL =
                REACT_APP_MODE === 'production'
                    ? REACT_APP_PRODUCTION_URL
                    : REACT_APP_MODE === 'development'
                      ? REACT_APP_DEVELOPEMENT_URL
                      : REACT_APP_LOCAL_API_URL
            vFetch(`/v2/google/auth`, {
                method: 'POST',
                body: JSON.stringify({ name: 'Google', code: googleCode, user_id: user.id, email: user.email }),
                cb: (res: any) => {
                    if (res.success) {
                        setTokens(res.tokens)
                        window.history.pushState({ data: 'Token success' }, 'Token sucess', `${API_URL}/procurement`)
                    } else if (res.authorizationURL) {
                        window.location.replace(res.authorizationURL)
                    }
                },
            })
        }
    }, [])

    useEffect(() => {
        if (activeModal) {
            document.body.classList.add('overflow-y-hidden')
        } else {
            document.body.classList.remove('overflow-y-hidden')
        }
        return () => document.body.classList.remove('overflow-y-hidden')
    }, [activeModal])

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }

    return (
        <div className=''>
            <div className='flex justify-between  bg-bg2 relative dark:bg-darkbg2'>
                <div className='flex gap-4'>
                    <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Procurement</h1>
                </div>
                <div className='flex gap-[8px] absolute items-center top-[-16px] right-2'>
                    <Link
                        draggable={false}
                        className={cn(!lastViewedCompany && 'pointer-events-none opacity-50', '')}
                        to={`/procurement${lastViewedCompany}`}
                    >
                        <Button variant={'outline'}>Return to Last Company</Button>
                    </Link>
                    <MoreActions options={options} displayContent={{ icon: FaBars }} />
                    <RefreshButton
                        queryKeys={[
                            procurementQueries.companies._def.toString(),
                            procurementQueries.tasks._def.toString(),
                        ]}
                    />
                </div>
            </div>
            <div className={cn('flex justify-between relative bg-bg1 dark:bg-darkbg2')}>
                <div className='flex gap-1   mt-2 bg-2'>
                    <button
                        onClick={() => {
                            handleChangeView('companies')
                            setSearchParams((prev: any) => {
                                prev.set('tab', 'companies')
                                return prev
                            })
                        }}
                        className={`mb-[-16px] py-1 px-[16px] rounded-t-[4px] font-bold  max-h-10 ${
                            view === 'companies'
                                ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                                : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                        }`}
                    >
                        Companies
                    </button>
                    <button
                        onClick={() => {
                            handleChangeView('tasks')
                            setSearchParams((prev: any) => {
                                prev.set('tab', 'tasks')
                                return prev
                            })
                        }}
                        className={`mb-[-16px] py-1 px-[16px] rounded-t-[4px] font-bold  max-h-10 ${
                            view === 'tasks'
                                ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                                : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                        }`}
                    >
                        Tasks
                    </button>
                    <button
                        onClick={() => {
                            handleChangeView('categories')
                            setSearchParams((prev: any) => {
                                prev.set('tab', 'categories')
                                return prev
                            })
                        }}
                        className={`mb-[-16px] py-1 px-[16px] rounded-t-[4px] font-bold  max-h-10 ${
                            view === 'categories'
                                ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                                : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                        }`}
                    >
                        Categories
                    </button>
                    {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                        <button
                            onClick={() => {
                                handleChangeView('sales')
                                setSearchParams((prev: any) => {
                                    prev.set('tab', 'sales')
                                    return prev
                                })
                            }}
                            className={`mb-[-16px] py-1 px-[16px] rounded-t-[4px] font-bold  max-h-10 ${
                                view === 'sales'
                                    ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                                    : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                            }`}
                        >
                            Sales
                        </button>
                    )}
                </div>
                <div className='flex gap-6'>
                    {view === 'tasks' && (
                        <div className='flex gap-4 items-center mt-[-18px]'>
                            <div className='grid grid-cols-[auto_auto_auto_auto_auto] grid-rows-2 gap-x-4 gap-y-1 items-center h-fit'>
                                <div className='grid grid-rows-subgrid row-span-2'>
                                    <FilterBarSelectorButton
                                        variant='outline'
                                        align='end'
                                        setFilterValues={() => {}}
                                        options={typeOptions}
                                        title={'Type'}
                                        field={'types'}
                                        filterValues={typeValues}
                                        searchToggle={false}
                                        editSearchParams={true}
                                        preview={false}
                                    />
                                </div>
                                <div className='grid grid-rows-subgrid row-span-2'>
                                    <FilterBarSelectorButton
                                        variant='outline'
                                        align='end'
                                        setFilterValues={() => {}}
                                        options={statusOptions}
                                        title={'Status'}
                                        field={'statuses'}
                                        filterValues={statusValues}
                                        searchToggle={false}
                                        editSearchParams={true}
                                        preview={false}
                                    />
                                    <FilterBarSelectorButton
                                        variant='outline'
                                        align='end'
                                        setFilterValues={() => {}}
                                        options={priorityOptions}
                                        title={'Priority'}
                                        field={'priorities'}
                                        filterValues={priorityValues}
                                        searchToggle={false}
                                        editSearchParams={true}
                                        preview={false}
                                    />
                                </div>
                                <div className='grid grid-rows-subgrid row-span-2 border h-fit rounded-md p-1 px-2  text-sm'>
                                    {selectionOptions?.map((option: any) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    setGroupFilterValues((prev: any) => {
                                                        const newValue = {
                                                            ...prev,
                                                        }
                                                        newValue[option.id] = !newValue[option.id]
                                                        handleFilterValuesEdit(newValue)
                                                        return newValue
                                                    })
                                                }}
                                                className='flex gap-1 cursor-pointer items-center'
                                            >
                                                <div
                                                    className={cn(
                                                        ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                        groupFilterValues[option.id]
                                                            ? 'bg-primary text-primary-foreground'
                                                            : 'opacity-50 [&_svg]:invisible'
                                                    )}
                                                >
                                                    <CheckIcon className={cn('h-4 w-4')} />
                                                </div>
                                                <p>{option.title}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='grid grid-rows-subgrid row-span-2'>
                                    <div className=' h-fit  p-1 flex justify-end gap-2 text-sm'>
                                        {userFilterOptions?.map((option: any) => {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        if (groupFilterValues[option.id]) {
                                                            return
                                                        }
                                                        setGroupFilterValues((prev: any) => {
                                                            const newValue = {
                                                                ...prev,
                                                            }
                                                            if (option.id === 'showAll') {
                                                                newValue['showMyself'] = false
                                                            }
                                                            if (option.id === 'showMyself') {
                                                                newValue['showAll'] = false
                                                            }
                                                            newValue[option.id] = !newValue[option.id]
                                                            handleFilterValuesEdit(newValue)
                                                            return newValue
                                                        })
                                                    }}
                                                    className='flex gap-1 cursor-pointer items-center'
                                                >
                                                    <div
                                                        className={cn(
                                                            ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                            groupFilterValues[option.id]
                                                                ? 'bg-primary text-primary-foreground'
                                                                : 'opacity-50 [&_svg]:invisible'
                                                        )}
                                                    >
                                                        <CheckIcon className={cn('h-4 w-4')} />
                                                    </div>
                                                    <p>{option.title}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <FilterBarSelectorButton
                                        variant='outline'
                                        align='end'
                                        setFilterValues={() => {}}
                                        options={userOptions}
                                        title={'Assigned To'}
                                        field={'assigned_to'}
                                        filterValues={assignedToValues}
                                        searchToggle={true}
                                        editSearchParams={true}
                                        preview={false}
                                    />
                                </div>
                                <div className='grid grid-rows-subgrid row-span-2 h-full min-w-[200px]'>
                                    <div
                                        onClick={() => {
                                            departments?.map((department: any) => {
                                                searchParams.set(`${department.title}_page`, '1')
                                            })
                                            searchParams.set('page', '1')
                                            searchParams.delete('calendarPreviewUser')
                                            searchParams.delete('calendarPreviewType')
                                            setSearchParams(searchParams)
                                            return setTaskDueDate(getDateToday())
                                        }}
                                        className={cn(
                                            'text-text2 dark:text-darktext2 dark:border-darkbg2 border border-bg1 h-fit w-fit rounded-md px-1  text-sm',
                                            taskDueDate?.toDateString() != getDateToday().toDateString() &&
                                                'border-lightgrey text-text1 cursor-pointer dark:text-darktext1 dark:border-lightgrey'
                                        )}
                                    >
                                        Reset
                                    </div>
                                    <V2TaskCalendar date={taskDueDate} setDate={setTaskDueDate} />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='flex gap-8'>
                        <button
                            className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all mr-[-14px]'
                            onClick={toggleCreateModal}
                        >
                            <FaTasks className='text-darkgrey dark:text-darktext1  p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                            <p className='font-medium leading-[1] uppercase text-[12px]'>Task</p>
                        </button>
                        <button
                            className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                            onClick={() => {
                                toggleModal('create-company')
                            }}
                        >
                            <div className=' flex justify-center items-center text-darkgrey dark:text-offwhite p-0 w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite'>
                                <FaBuilding />
                            </div>
                            <p className='font-medium leading-[1] uppercase text-[12px]'>Company</p>
                        </button>
                        <button
                            className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                            onClick={() => {
                                toggleModal('create-category')
                            }}
                        >
                            <div className=' flex justify-center items-center text-darkgrey dark:text-offwhite p-0 w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite'>
                                <FaClipboardList />
                            </div>
                            <p className='font-medium leading-[1] uppercase text-[12px]'>Category</p>
                        </button>
                    </div>
                </div>
            </div>
            <div className='rounded-[4px] shadow-small dark:bg-darkaccent min-h-[80vh] bg-bg1'>
                <>
                    {(view === 'companies' || view === 'my-companies') && (
                        <CompanyListV2
                            userSelectedCompanyFilterValues={userSelectedCompanyFilterValues}
                            setUserSelectedCompanyFilterValues={setUserSelectedCompanyFilterValues}
                        />
                    )}
                    {view === 'categories' && <CategoryList categories={categories} />}
                    {view === 'tasks' && !departmentConfigQuery.isLoading && <TaskListV2 taskFilters={filters} />}
                    {view === 'sales' && (
                        <CompanySalesList
                            userSelectedCompanyFilterValues={userSelectedCompanyFilterValues}
                            setUserSelectedCompanyFilterValues={setUserSelectedCompanyFilterValues}
                        />
                    )}
                </>
            </div>
            {activeModal === 'missing-vendors' && <MissingVendorsModal />}
            {activeModal === 'create-company' && <CreateCompanyModalV2 />}
            {activeModal === 'create-category' && <CreateCategory />}
            {(activeModal === 'listing' || activeModal === 'company') && (
                <CompanyProvider>{activeModal === 'company' && <CompanyModalV2 />}</CompanyProvider>
            )}

            {activeModal === 'parent' && <ParentCompanyModal parentCompany={selectedCompany.parent_company} />}
            {showCreateModal && (
                <CreateTaskModal
                    prePopulatedValues={{
                        HTMLDateString: HTMLDateInputFormatter(addBusinessDays(new Date(), 1)),
                        department_id: departments?.find((department: any) => department.title === 'Procurement').id,
                        priority: 'Low',
                        status: 'To Do',
                        associations: selectedCompany
                            ? [
                                  {
                                      resource_name: 'user',
                                      readable_name: `${user.firstName} ${user.lastName}`,
                                      resource_id: user.id,
                                  },
                                  {
                                      resource_name: 'company',
                                      readable_name: `${selectedCompany?.name}`,
                                      resource_id: selectedCompany.id,
                                  },
                              ]
                            : [
                                  {
                                      resource_name: 'user',
                                      readable_name: `${user.firstName} ${user.lastName}`,
                                      resource_id: user.id,
                                  },
                              ],
                    }}
                    closeModal={() => {
                        toggleCreateModal()
                        if (!urlParamCompanyId) {
                            setSelectedCompany(undefined)
                        }
                    }}
                />
            )}
        </div>
    )
}
