import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
    FulfillmentOrderInit,
    LineItemInit,
    OrderAction,
    OrderTimelineEvent,
    OrderInit,
    defaultOrder,
    OrderNoteInit,
} from '../orders/orders.types'
import {
    closePopout,
    createOrderNotesArray,
    fetchActiveUsers,
    fetchAllUsers,
    parseResObject,
    sendToast,
    vFetch,
} from '../helpers'
import FraudAnalysis from '../orders/components/FraudAnalysis'
import Tags from '../orders/components/Tags'
import PaymentStatus from '../orders/components/PaymentStatus'
import RemovedItems from '../orders/components/lineItems/RemovedItems'
import FulfilledItems from '../orders/components/lineItems/FulfilledItems'
import UnfulfilledItems from '../orders/components/lineItems/UnfulfilledItems'
import OrderHeader from '../orders/components/OrderHeader'
import Customer from '../orders/components/Customer'
import { ReturnEditInit } from '../returns/returns.types'
import {
    PrivateEventType,
    TaskCreateInit,
    TaskEditInit,
    TaskTimelineEventV2,
    defaultTaskCreate,
    topicMap,
} from '../tasks/tasks.types'
import { PurchaseOrderEditInit } from '../purchaseOrders/purchaseOrders.types'
import TaskEditCreateModal from '../tasks/components/TaskEditCreateModal'
import ReturnsBlock from '../orders/components/ReturnsBlock'
import { UserInit } from '../users/users.types'
import { Events } from '../orders/components/Events'
import { refreshPurchaseOrders, refreshReturns, refreshTasks } from '../orders/orders.helpers'
import { useSelector } from 'react-redux'
import PurchaseOrdersList from '../purchaseOrders/components/PurchaseOrdersList'
import TailSpin from 'react-loading-icons/dist/esm/components/tail-spin'
import { EmailInit } from '../emails/email.types'
import ManufacturerOrderNumbers from 'orders/components/ManufacturerOrderNumbers'
import InvoicePaymentStatus from 'orders/components/InvoicePaid'
import FraudAnalysisV2 from 'orders/components/FraudAnalysisV2'
import Chargebacks from 'orders/components/Chargebacks'

export default function OrderHubScreen() {
    const { orderId } = useParams()
    const navigate = useNavigate()
    const [params, setSearchParams] = useSearchParams()
    const [showDiscount, setShowDiscount] = useState<string>('')
    const user = useSelector<any, any>((state) => state.user)
    const [loading, setLoading] = useState(false)
    const [allInitsLoaded, setAllInitsLoaded] = useState(false)

    const [order, setOrder] = useState<OrderInit>(defaultOrder)
    const [orderEvents, setOrderEvents] = useState<OrderTimelineEvent[]>([]) // shopify timeline events
    const [orderEventsPrivate, setOrderEventsPrivate] = useState<PrivateEventType[]>([])
    const [orderTags, setOrderTags] = useState<string[]>([])
    const [fulfillmentOrders, setFulfillmentOrders] = useState<FulfillmentOrderInit[]>([])

    const [activeUserList, setActiveUserList] = useState<UserInit[]>([])
    const [userList, setUserList] = useState<UserInit[]>([])
    const [selectedTab, setSelectedTab] = useState<string>('tasks')
    const [events, setEvents] = useState<any>([])
    const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrderEditInit[]>([])
    const [returns, setReturns] = useState<ReturnEditInit[]>([])
    const [tasks, setTasks] = useState<(TaskEditInit | TaskCreateInit)[]>([])
    const [taskEvents, setTaskEvents] = useState<TaskTimelineEventV2[]>([])
    const [emails, setEmails] = useState<EmailInit[]>([])
    const [adminEventLogged, setAdminEventLogged] = useState(false)
    const [orderEventPrivateLogged, setOrderEventPrivateLogged] = useState(false)
    const [refetchAttachments, setRefetchAttachments] = useState(false)

    // const [files, setFiles] = useState([])

    const eventSource: any = {
        foreign_id: orderId,
        foreign_table: 'orders',
    }

    const tabs: any = {
        middle: {
            tasks: tasks,
            returns: returns,
            'purchase orders': purchaseOrders,
            // hidiing this for now since files is not set up
            // files: files,
        },
        right: {},
    }

    const remainingTaskTypes = Object.keys(topicMap).filter(
        (task_type) =>
            !tasks
                .filter((t) => (t as TaskEditInit).id)
                .map((t) => t.task_type)
                .includes(task_type) && !['select type', 'sales'].includes(task_type.toLowerCase())
    )
    const canCreateTask = remainingTaskTypes.length > 0 && tasks.every((task) => (task as TaskEditInit).id)
    const newTask = {
        ...defaultTaskCreate,
        assigned_to: [user.user_id],
        customer: order.customer,
        order_id: order.id,
        order_name: order.name,
        order_notes: order.note || '',
        task_type: remainingTaskTypes[0],
    }
    function addNewTask() {
        if ((tasks as TaskEditInit[]).some((t) => !t.id))
            return sendToast({ message: 'Please only create 1 new task at a time.' })
        if (canCreateTask) return setTasks([...tasks, newTask])
        else
            return sendToast({
                message: 'Sorry, no more tasks can be created. All task types are being used for this type of order.',
            })
    }

    const orderActions: OrderAction[] = []

    useEffect(() => {
        if (!orderEventPrivateLogged) {
            if (user && order) {
                vFetch(`/v1/orderEventsPrivate`, {
                    skipToast: true,
                    method: 'POST',
                    body: JSON.stringify({
                        order_id: orderId,
                        user_id: user.id,
                        event_type: 'viewed',
                    }),
                    cb: (res: any) => {
                        if (res.success) {
                            setOrderEventPrivateLogged(true)
                        }
                    },
                })
            }
        }
    }, [])
    function refresh() {
        setLoading(true)
        fetchAllUsers(setUserList)
        fetchActiveUsers(setActiveUserList)
        vFetch(`/v1/orders/${orderId}/fulfillment-orders`, {
            cb: (res: any) => setFulfillmentOrders(res.fulfillment_orders),
        })
        vFetch(`/v1/orderEventsPrivate?order_id=${orderId}`, {
            cb: (res: any) => setOrderEventsPrivate(res.orderEventsPrivate),
        })
        vFetch(`/v1/orders/${orderId}/events`, {
            cb: (res: any) =>
                setOrderEvents(
                    res.events.map((event: any) => {
                        return { ...event.node, normalized_event_type: 'Shopify Timeline' }
                    })
                ),
        })
        vFetch(`/v1/orders/${orderId}`, {
            cb: (res: any) => {
                setLoading(false)
                if (res.success) {
                    const resOrder = parseResObject({ ...res.order })
                    refreshTasks(resOrder, setTasks, setTaskEvents, user, setAdminEventLogged, adminEventLogged)
                    refreshPurchaseOrders(resOrder, setPurchaseOrders)
                    refreshReturns(resOrder, setReturns)

                    vFetch(
                        `/v1/products/limited/handles?gid=${resOrder.line_items.map(
                            (i: LineItemInit) => `gid://shopify/Product/${i.product_id}`
                        )}`,
                        {
                            cb: (pRes: any) => {
                                if (pRes.success) {
                                    pRes.products.forEach(
                                        (p: any) =>
                                            (resOrder.line_items.find(
                                                (i: LineItemInit) =>
                                                    i.product_id == p.gid.replace('gid://shopify/Product/', '')
                                            ).handle = p.handle)
                                    )
                                    setOrder(resOrder)
                                    setOrderTags(resOrder.tags.split(', '))
                                    setAllInitsLoaded(true)
                                }
                            },
                        }
                    )
                }
            },
        })
    }

    useEffect(() => {
        refresh()

        const listenToWindow = (e: MouseEvent) => closePopout(e, ['js-order__discount'], setShowDiscount)
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    useEffect(() => {
        if (
            JSON.stringify(order) !== JSON.stringify(defaultOrder) &&
            JSON.stringify(order.tags.split(', ')) !== JSON.stringify(orderTags)
        ) {
            vFetch(`/v1/orders/${orderId}`, {
                method: 'PUT',
                body: JSON.stringify({ id: order.id, fields: { tags: orderTags } }),
            })
        }
    }, [orderTags])
    useEffect(() => {
        setEvents([...orderEvents, ...taskEvents, ...createOrderNotesArray(order.note || '', userList), ...emails])
    }, [order, orderEvents, taskEvents, emails])

    useEffect(() => {
        if (purchaseOrders.length) {
            vFetch(
                `/v1/email?table_id=${[`orders=${orderId}`, purchaseOrders.map((po) => `purchase_orders=${po.id}`)]
                    .flat()
                    .join(',')}`,
                {
                    cb: (res: any) =>
                        setEmails(
                            res.emails.map((email: any) => {
                                return { ...parseResObject(email), normalized_event_type: 'Email' }
                            })
                        ),
                }
            )
        } else {
            vFetch(`/v1/email?table_id=orders=${orderId}`, {
                cb: (res: any) =>
                    setEmails(
                        res.emails.map((email: any) => {
                            return { ...parseResObject(email), normalized_event_type: 'Email' }
                        })
                    ),
            })
        }
    }, [tasks, purchaseOrders])

    function onEmailSubmit(): any {
        vFetch(
            `/v1/email?table_id=${[`orders=${orderId}`, purchaseOrders.map((po) => `purchase_orders=${po.id}`)]
                .flat()
                .join(',')}`,
            {
                cb: (res: any) =>
                    setEmails(
                        res.emails.map((email: any) => {
                            return { ...parseResObject(email), normalized_event_type: 'Email' }
                        })
                    ),
            }
        )
    }

    return (
        <div className='flex flex-col gap-[8px] text-text1 dark:text-darktext1'>
            {loading && (
                <div className='grid fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-50'>
                    <TailSpin stroke={'#42EFD0'} />
                </div>
            )}
            {/* <OrderHeader
                userList={userList}
                orderEventsPrivate={orderEventsPrivate}
                order={order}
                tasks={tasks.filter((t) => !!(t as TaskEditInit).id) as TaskEditInit[]}
                actions={orderActions}
                addNewTask={addNewTask}
            />
            /> */}
            {order.id && <Chargebacks order_id={order.id} />}
            {/* Info columns */}
            {allInitsLoaded && (
                <div className='flex gap-[8px] items-start justify-start'>
                    {/* left column */}
                    <div className='flex flex-col gap-[8px] basis-[calc(33.3%-32px)] grow min-w-[450px]'>
                        <UnfulfilledItems order={order} fulfillmentOrders={fulfillmentOrders} />
                        <FulfilledItems order={order} />
                        <RemovedItems order={order} fulfillmentOrders={fulfillmentOrders} />
                        {/* {order.note_attributes.length > 0 && (
                            <div className='grid bg-bg1 dark:bg-darkbg1 shadow-small rounded dark:text-darktext1 [&>*]:p-[16px] [&>*]:border-b-[1px] [&>*]:dark:border-darkgrey [&>:last-child]:border-0'>
                                <div>
                                    <h2 className='text-[20px] font-bold mb-[4px]'>Additional Details</h2>
                                    {order.note_attributes.map((attr, i) => {
                                        if (attr.name === 'shipping_estimate') {
                                            return (
                                                <div className='mb-[8px]' key={`order__note-attribute-${i}`}>
                                                    <h3 className='capitalize font-semibold text-[16px] mb-[4px]'>
                                                        {attr.name.replace('_', ' ')}
                                                    </h3>
                                                    {JSON.stringify(attr.value)
                                                        .split('\n')
                                                        .map((line) => (
                                                            <p className='mb-[8px] text-[14px] break-all'>
                                                                {line.split(':').join(': ')}
                                                            </p>
                                                        ))}
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className='mb-[8px]' key={`order__note-attribute-${i}`}>
                                                    <h3 className='capitalize font-semibold text-[16px] mb-[4px]'>
                                                        {attr.name.replace('_', ' ')}
                                                    </h3>
                                                    {JSON.stringify(attr.value)
                                                        .split('\n')
                                                        .map((line) => (
                                                            <p className='mb-[8px] text-[14px] break-all'>{line}</p>
                                                        ))}
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        )} */}
                        {order.id && <ManufacturerOrderNumbers order_id={order.id.toString()} />}
                        {order.id && (
                            <InvoicePaymentStatus
                                custom_invoice_paid={(order as any).custom_invoice_paid}
                                order_id={order.id.toString()}
                            />
                        )}
                        <PaymentStatus order={order} fulfillmentOrders={fulfillmentOrders} />
                        <Customer
                            order={order}
                            eventSource={eventSource}
                            onEmailSubmit={onEmailSubmit}
                            refetchAttachments={refetchAttachments}
                            setRefetchAttachments={setRefetchAttachments}
                        />
                        {order.risk ? <FraudAnalysisV2 order={order} /> : <FraudAnalysis order={order} />}
                        <Tags tags={orderTags} setTags={setOrderTags} />
                    </div>

                    <div className='flex flex-wrap gap-[8px] basis-[calc(66.6%-32px)] grow items-start justify-start'>
                        {/* middle column  */}
                        <div className='flex flex-col gap-x-[8px] basis-[calc(33%-32px)] min-w-[400px] grow shrink'>
                            <div className='relative top-[1px] flex gap-[8px] ml-[8px] border-b-darkgrey'>
                                {Object.keys(tabs.middle).map((tab) => (
                                    <button
                                        className={`border-black dark:border-darkgrey border-[1px] border-b-0 ${
                                            selectedTab === tab
                                                ? 'bg-white dark:bg-darkness z-index-1'
                                                : '!border-transparent'
                                        } rounded-t px-[4px] capitalize`}
                                        onClick={() => setSelectedTab(tab)}
                                    >
                                        {tab} ({tabs.middle[tab].length})
                                    </button>
                                ))}
                            </div>
                            <div className='grid gap-[8px] border-[1px] border-black dark:border-darkgrey rounded p-[8px]'>
                                {selectedTab === 'tasks' && (
                                    <div className='flex flex-col gap-[8px]'>
                                        {tasks.map((task, i) => (
                                            <TaskEditCreateModal
                                                refreshProp={() =>
                                                    refreshTasks(
                                                        order,
                                                        setTasks,
                                                        setTaskEvents,
                                                        user,
                                                        setAdminEventLogged,
                                                        adminEventLogged
                                                    )
                                                }
                                                task={task}
                                                setTask={(task: TaskEditInit) =>
                                                    setTasks(
                                                        tasks.map((t) =>
                                                            (t as TaskEditInit).id === task.id ? task : t
                                                        )
                                                    )
                                                }
                                                order={order}
                                                setOrder={setOrder}
                                                possibleTypes={
                                                    (task as TaskEditInit).id
                                                        ? undefined
                                                        : remainingTaskTypes.filter(
                                                              (type) => type.toLowerCase() !== 'sales'
                                                          )
                                                }
                                                userList={activeUserList}
                                                setLoading={setLoading}
                                            />
                                        ))}
                                        {canCreateTask && (
                                            <div className='flex justify-start items-center gap-[8px]'>
                                                <button
                                                    className='px-[8px] py-[2px] uppercase text-fire border-[2px] border-fire font-semibold rounded'
                                                    onClick={addNewTask}
                                                >
                                                    + Task
                                                </button>
                                                {tasks.length === 0 && <p>This order currently has no tasks.</p>}
                                            </div>
                                        )}
                                    </div>
                                )}

                                {selectedTab === 'returns' && (
                                    <ReturnsBlock
                                        order={order}
                                        alwaysOpen={true}
                                        propReturns={returns}
                                        setPropReturns={setReturns}
                                    />
                                )}
                                {selectedTab === 'purchase orders' && (
                                    <div className='w-[100%]'>
                                        <PurchaseOrdersList purchaseOrders={purchaseOrders} />
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* right column */}
                        <div className='flex flex-col gap-[8px] basis-[calc(33%-32px)] min-w-[400px] grow shrink'>
                            {/* <Events
                                events={events}
                                userList={userList}
                                tasks={tasks}
                                addNewTask={addNewTask}
                                order={order}
                                setOrder={setOrder}
                            /> */}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
